import { AxiosResponse } from "axios";
import { DOMAIN_NAME } from "config";
import { getFromLocalStorage, LSKeys } from "utils/ls.utils";

import { createAxiosInstanceWithAccessToken } from "utils/utils";

const BASE_URL = process.env.REACT_APP_SERVER_URL + "/v1";

export async function getDashboardAnalytics(
  filterProps: FilterParams
): Promise<AxiosResponse<AxiosCommonResponse<DashboardAnalytics>>> {
  const QuoteAPI = createAxiosInstanceWithAccessToken();
  return await QuoteAPI.post(
    `${BASE_URL}/portal_broker/dashboard`,
    filterProps,
    {
      headers: {
        "X-STERE-DOMAIN": DOMAIN_NAME,
        "X-STERE-USER-TOKEN": getFromLocalStorage(LSKeys.ACCESS_TOKEN),
      },
    }
  );
}
