import { IGetAppointments, IFetchAppointmentsList, IRequestAppointment } from "modules/Appointments/types";
import api from "utils/api.utils";

export const fetchMemberAppointments = {
  queryFn: async ({ memberId, accountId }: IGetAppointments) => {
    try {
      const response = await api.get(`members/${memberId}`, {
        "X-ACCOUNT-ID": accountId,
      });
      return response;
    } catch (err) {
      throw err;
    }
  },
  queryKey: ({ memberId, accountId }: IGetAppointments) => [
    memberId,
    accountId,
    "appointments",
  ],
};

export const requestAppointments = {
  mutationFn: async ({ productId, accountId }: IRequestAppointment) => {
    try {
      const response = await api.post(`appointments`, { product_id: productId }, {
        "X-ACCOUNT-ID": accountId,
      });
      return response;
    } catch (err) {
      throw err;
    }
  }
}

export const fetchAppointmentsList = {
  mutationFn: async (reqBody: IFetchAppointmentsList, accountId: string) => {
    try {
      const response = await api.post(`appointments/filter`, reqBody, {
        "X-ACCOUNT-ID": accountId,
      });
      return response;
    } catch (err) {
      throw err;
    }
  }
}

export const getProducts = {
  queryFn: async (productName: string) => {
    const response = await api.get(
      `insurance-products${productName ? `?search=${productName}` : ''}`
    );
    return response as Promise<{ insurance_products: string[] }>;
  },
  queryKey: (productName: string) => [
    'appointment',
    'insurance-products',
    productName,
  ],
};

export const getInsurerProducts = {
  queryFn: async (insurerId: string, accountId: string) => {
    const response = await api.post('insurer-products/filter', {
      insurer_id: insurerId,
      limit_by: {
        page: 1,
        per_page: 20
      },
    }, {
      "X-ACCOUNT-ID": accountId,
    });
    return response as Promise<{
      data: Array<Record<string, string>>,
    }>;
  },
  queryKey: (insurerId: string, accountId: string) => [
    'appointment',
    'insurer-product',
    insurerId,
    accountId
  ],
}

export const getInsurers = {
  queryFn: async (insurerName: string, accountId: string) => {
    const response = await api.post(
      'insurers/filter', {
      limit_by: {
        page: 1,
        per_page: 30
      },
      insurer_name: insurerName
    }, {
      "X-ACCOUNT-ID": accountId,
    });
    return response as Promise<{ data: Record<string, string>[] }>;
  },
  queryKey: (insurerName: string, accountId: string) => [
    'appointment',
    'insurers',
    insurerName,
    accountId
  ],
}