import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { Outlet } from "react-router-dom";

import { Support } from "components/Support";
import Header from "components/common/Header/Header";
import Sidebar from "components/common/Sidebar/Sidebar";
import { footerNoteSelector } from "portalConfig/selectors";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";

const Home = () => {
  const isMobile = useMediaQuery("(max-width:820px)");
  const customStyles = useSelector(customStylesSelector);
  const footerNote = useSelector(footerNoteSelector);
  const sanitizedHtml = DOMPurify.sanitize(footerNote);

  const selectedAccount = useSelector(
    (state: RootState) => state.auth.accounts.selectedAccount
  );
  const isSidebarHidden =
    getFromLocalStorage(LSKeys.ACCESS_TOKEN) &&
    !selectedAccount;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: "20px",
        height: "99vh",
        overflow: "scroll",
      }}
    >
      <Box>
        <Header />
      </Box>

      <Grid
        container
        spacing={0}
        rowSpacing={0}
        sx={{
          flexGrow: 1,
          overflowY: "hidden",
          ...(footerNote ? { minHeight: "90vh" } : {}),
        }}
      >
        {isSidebarHidden ? null : (
          <Grid
            item
            xs={isMobile ? 2 : 3}
            sm={isMobile ? 2 : 3}
            md={isMobile ? 2 : 3}
            lg={isMobile ? 2 : 3}
            className="customStepperClass"
            sx={{
              height: "100%",
              overflowY: "auto",
            }}
          >
            <Sidebar />
          </Grid>
        )}
        <Grid
          item
          xs={isSidebarHidden ? 12 : isMobile ? 10 : 9}
          sm={isSidebarHidden ? 12 : isMobile ? 10 : 9}
          md={isSidebarHidden ? 12 : isMobile ? 10 : 9}
          lg={isSidebarHidden ? 12 : isMobile ? 10 : 9}
          sx={{
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            backgroundColor: "#F5F5F5",
            p: "20px",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              p: "20px",
              background: "white",
              borderRadius: "20px",
              minHeight: "calc(100% - 40px)",
            }}
          >
            <Outlet />
          </Box>
        </Grid>
      </Grid>

      <Support />
      {footerNote && (
        <Box
          sx={{
            mt: "40px",
            p: "20px",
            background: customStyles?.muiTheme?.palette?.primary?.main,
          }}
        >
          <Typography fontSize={12} color={"white"}>
            {parse(sanitizedHtml)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Home;
