export interface IAgencyDetails {
  name: string;
  regulatory_info?: Record<string, string>[];
  address: string;
}

export enum ERole {
  AGENT = "agent",
  ADMIN = "admin",
}
export interface IChildAccount {
  id: string;
  status: string;
  creator_user_id: string;
  related_entity_type: string;
  related_entity_id: string;
  name: string;
  member: {
    id: string;
    status: string;
    user: string;
    account: string;
    type: string;
    roles: ERole[];
  };
  parent_account: string;
  created_at: string;
}

export interface ICreateAccountRes {
  id: string;
  object: string;
  creator_user_id: string;
  name: string;
  type: string;
  keys: [
    {
      id: string;
      account_id: string;
      name: string;
      mode: string;
      publishable_key: string;
      secret_key: string;
    }
  ];
}

export interface IGenerateKeysRes {
  id: string;
  object: string;
  account_id: string;
  name: string;
  mode: string;
  status: string;
  scope: string | null;
  publishable_key: string;
  secret_key: string;
  created_at: string;
  updated_at: string;
}
