import { CircularProgress, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { FunctionComponent } from "react";

import ActionPopover from "components/common/ActionPopover/ActionPopover";
import TableHeaderComponent from "./TableHeaderComponent";

interface TableComponentProps {
  headCells: TableComponentCell[];
  rows: TableComponentRow[];
  loading: boolean;
  errorMessage?: React.ReactNode;
  noDataMessage?: React.ReactNode;
}

const tableCellStyle = { color: "black", paddingLeft: "0px" };

const TableComponent: FunctionComponent<TableComponentProps> = ({
  headCells,
  rows,
  loading,
  errorMessage,
  noDataMessage,
}) => {
  const colSpan = headCells.length
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750, minHeight: "200px", tableLayout: "fixed" }}
        aria-labelledby="tableTitle"
        size={"medium"}
      >
        <TableHeaderComponent headCells={headCells} />
        <TableBody>
          {!loading &&
            rows?.map((row) => (
              <TableRow hover key={row.id ?? Math.random()}>
                {row.cells.map((cell) => (
                  <TableCell
                    key={cell.id ?? Math.random()}
                    sx={{
                      color: "black",
                      maxWidth: "20px",
                      paddingLeft: "0px",
                      whiteSpace: "break-spaces",
                      wordWrap: "break-word",
                    }}
                    align="left"
                  >
                    {cell.type === "action" ? (
                      cell.actions.length ? (
                        <ActionPopover listOptions={cell.actions} />
                      ) : null
                    ) : (
                      cell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {loading && (
            <TableRow>
              <TableCell colSpan={colSpan} style={{ textAlign: "center" }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {!rows?.length && !loading && (
            <TableRow>
              <TableCell colSpan={colSpan} style={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    ...tableCellStyle,
                    fontSize: "0.875rem",
                    fontWeight: 500,
                  }}
                >
                  {!!errorMessage ? errorMessage : noDataMessage}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
