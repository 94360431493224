import { useQuery } from "@tanstack/react-query";
import { fetchMembersApi } from "modules/Agents/api";
import { IFetchMemberPayload } from "modules/Agents/types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMembershipRequest } from "store/features/authSlice";
import { RootState } from "store/store";

function useRequestedMembersInfo() {
  const accountId = useSelector(
    (state: RootState) => state.auth.accounts?.selectedAccount?.id
  );
  const dispatch = useDispatch();
  const {
    data,
    isLoading,
  } = useQuery({
    queryFn: () => fetchMembersApi({
      limit_by: {
        "page": 1,
        "per_page": 30
      },
      expand: [
        "user",
      ],
      statuses: ["requested"]
    } as IFetchMemberPayload, accountId),
    queryKey: ['get-membership-request', accountId],
    enabled: Boolean(accountId),
    retry: 0,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (data?.data) {
      dispatch(setMembershipRequest(data.data))
    }
  }, [data?.data, dispatch])

  return { isRequestMemberListLoading: isLoading }
}

export default useRequestedMembersInfo;