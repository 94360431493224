import * as yup from "yup";

export const loginCredSchema = yup.object().shape({
  email: yup.string().email().required().label("Email"),
  password: yup.string().required().label("Password"),
});

export const v2loginCredSchema = yup.object().shape({
  email: yup.string().email().required().label("Email"),
});

export const v2OTPCredSchema = yup.object().shape({
  otp: yup
    .number()
    .required()
    .typeError("Please enter a valid One-time password.")
    .test("len", "One-time password must be exactly 6 digits.", (val) => {
      if (val) {
        return val.toString().length === 6;
      }
      return false;
    }),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email().required().label("Email"),
});

export const resetPasswordSchema = yup.object({
  password: yup.string().required("Please enter your new password."),
  confirmPassword: yup
    .string()
    .required("Please re-enter your new password.")
    .oneOf([yup.ref("password")], "Your passwords do not match."),
});
