import { Box, Card, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { approveMembershipRequest, rejectMembershipRequest } from "components/Notifications/api";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import useDashboardStartScreen from "hooks/useDashboardStartScreen";
import { IMember } from "modules/Agents/types";
import moment from "moment";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authSelector } from "store/selectors/selectors";
import { useT } from "translation";

function RequestMembershipCard({ membershipRequest }: { membershipRequest: IMember }) {
  // const userFullName = `${membershipRequest?.user?.first_name || ''} ${membershipRequest?.user?.last_name || ''}`;
  const userEmail = membershipRequest?.email || '';
  const timeStamp = membershipRequest.updated_at;
  const { t } = useT();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setToast } = useContext(ToastCtx)
  const dashboardStartScreen = useDashboardStartScreen();
  const {
    accounts: { selectedAccount },
  } = useSelector(authSelector);

  const handleError = ({ error }) => {
    const errorCode = error?.code || "";
    if (errorCode === "invalid_member_id") {
      setToast({
        severity: "error",
        text: t("INVALID_MEMBERSHIP_ID")
      })
      return;
    }
    if (errorCode === "insufficient_permissions") {
      setToast({
        severity: "error",
        text: t("NO_SUFFICIENT_PERMISSION"),
      });
      return;
    }
    if (errorCode === "user_already_member") {
      setToast({
        severity: "error",
        text: t("USER_IS_ALREADY_MEMBER"),
      });
      navigate(dashboardStartScreen);
      return;
    }
    setToast({
      severity: "error",
      text: t("SOMETHING_WENT_WRONG"),
    });
  }

  const { mutate: approveMembership, isPending: isApprovingMembership } = useMutation<
    unknown,
    unknown,
    string
  >({
    mutationFn: (membershipId: string) => approveMembershipRequest(membershipId, selectedAccount.id),
    onSuccess: () => {
      navigate(dashboardStartScreen);
      setToast({
        severity: "success",
        text: t("MEMBERSHIP_REQUEST_APPROVED"),
      });
      queryClient.invalidateQueries({
        queryKey: ['get-membership-request', selectedAccount.id],
      });
    },
    onError: handleError,
  });

  const { mutate: rejectMembership, isPending: isRejectingMembership } = useMutation<
    unknown,
    unknown,
    string
  >({
    mutationFn: (membershipId: string) => rejectMembershipRequest(membershipId, selectedAccount.id),
    onSuccess: () => {
      navigate(dashboardStartScreen);
      setToast({
        severity: "success",
        text: t("MEMBERSHIP_REQUEST_REJECTED"),
      });
      queryClient.invalidateQueries({
        queryKey: ['get-membership-request', selectedAccount.id],
      });
    },
    onError: handleError,
  });

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        p: "16px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          marginBottom: "8px",
        }}
      >
        <Box display="flex" gap={1}>
          <Typography
            variant="body1"
            sx={{
              color: "primary.main",
            }}
          >
            {`${userEmail} ${t("IS_REQUESTING_TO_JOIN")} ${selectedAccount.name}`}
          </Typography>
        </Box>
        <Typography variant="body1">
          {moment(timeStamp).format("DD MMM YYYY")}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "12px" }}>
        <AnimatedButton
          variant="outlined"
          isLoading={isRejectingMembership}
          onClick={() => rejectMembership(membershipRequest.id)}
        >
          {t("NOTIFICATION_PAGE.decline")}
        </AnimatedButton>
        <AnimatedButton
          variant="contained"
          isLoading={isApprovingMembership}
          onClick={() => approveMembership(membershipRequest.id)}
        >
          {t("NOTIFICATION_PAGE.accept")}
        </AnimatedButton>
      </Box>
    </Card>
  );
}

export default RequestMembershipCard;