import { Box, CircularProgress, Typography } from "@mui/material";
import { useT } from "translation";

export const RedirectLoader = () => {
  const { t } = useT();
  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: '#fff',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1300,
    }}
  >
    <CircularProgress
      sx={{
        color: '#fff',
        marginBottom: 2,
      }}
      size={60}
      thickness={4}
    />
    <Typography
      sx={{
        fontSize: '1.25rem',
      }}
    >
      {t("REDIRECT_TO_SOURCE")}
    </Typography>
  </Box>
}