export const productsByPortal: {
  [key: string]: SelectOption[];
} = {
  corx: [{ label: "Pet", value: "PET-MEU" }],
  fairfax: [{ label: "Grouplife", value: "GROUPLIFE-FAIRFAX" }],
  liberseguros: [{ label: "Auto", value: "AUTO-LIBER" }],
};

export const productTypeByPortal = {
  // This is a temporary solution. CorX can contain personal and business products as well
  corx: "personal",
  fairfax: "business",
  liberseguros: "business",
};
