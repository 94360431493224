import { Box } from "@mui/material";
import OutletHeader from "components/Home/Common/OutletHeader";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { agentsPageDataAttr } from "constants/dataAttributes";
import AgentInviteDialog from "modules/Agents/AgentInviteDialog";
import AgentsTabPanel from "modules/Agents/AgentsTabPanel";
import { useState } from "react";
import { useT } from "translation";

const Agents = () => {
  const { t } = useT();
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "12px",
      }}
      {...animateRouteProps}
    >
      <OutletHeader
        title={t("AGENTS_PAGE.title")}
        actionBtn={
          <AnimatedButton
            variant="contained"
            onClick={() => setIsInviteDialogOpen(!isInviteDialogOpen)}
            data_cy={agentsPageDataAttr.AGENT_INVITE_BTN}
          >
            {t("AGENTS_PAGE.inviteBtn")}
          </AnimatedButton>
        }
      />
      <AgentInviteDialog
        isInviteDialogOpen={isInviteDialogOpen}
        setIsInviteDialogOpen={setIsInviteDialogOpen}
      >
        <AgentsTabPanel />
      </AgentInviteDialog>
    </Box>
  );
};

export default Agents;
