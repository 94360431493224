import { Box, Typography } from "@mui/material";
import CustomDatePicker from "components/Datepicker/Datepicker";
import { useState } from "react";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import { fromDate, toDate } from "utils/utils";
import { Dayjs } from "dayjs";
import FilterListIcon from "@mui/icons-material/FilterList";
import AnimatedButton from "components/custom/AnimatedButton";
import { motion } from "framer-motion";
import DynamicFilterComponent from "components/common/DynamicFilterComponent";
import { cloneDeep } from "lodash";

interface FiltersContainerProps {
  title?: React.ReactNode;
  filters: Filter[];
  onDateChange?: (newValue: Dayjs, name: string) => void;
  extraFilters?: React.ReactNode;
  onApply: (filters: Record<string, string[]>) => void;
  onClear: (filters: Record<string, string[]>) => void;
  onFiltersInput?: (key: string, value: string) => void;
}

const filterVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0, height: 0, display: "none" },
};

function DynamicFilterContainer({ title,
  filters,
  onDateChange,
  onApply,
  onClear,
  extraFilters,
  onFiltersInput,
}: FiltersContainerProps) {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);

  const [filtersChanged, setFiltersChanged] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({});

  const toggleFilters = () => setShowFilters(!showFilters);

  const onChange = (newSelection: string[], filterId: string) => {
    const updatedFilter = cloneDeep(selectedFilters);
    updatedFilter[filterId] = newSelection
    setSelectedFilters(updatedFilter);
    setFiltersChanged(true);
  };

  const handleOnApplyClick = () => {
    onApply(selectedFilters);
    setFiltersChanged(false);
  };

  const handleOnClearClick = () => {
    setSelectedFilters({});
    onClear({});
    setFiltersChanged(false)
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {title}
        <Box>{extraFilters}</Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            ml: "auto",
          }}
        >
          {onDateChange && (
            <>
              <CustomDatePicker
                name="start_date"
                label={t("FILTERS.fromDate")}
                value={fromDate}
                onDateChange={(value) =>
                  onDateChange(value, "start_date")
                }
              />

              <CustomDatePicker
                name="end_date"
                label={t("FILTERS.toDate")}
                value={toDate}
                onDateChange={(value) =>
                  onDateChange(value, "end_date")
                }
              />
            </>
          )}
          {filters.length > 0 && (
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                fontSize: "14px",
                px: "12px",
                py: "6px",
                mt: "6px",
                borderRadius: "4px",
                background:
                  customStyles?.muiTheme?.palette?.background?.default,
              }}
              onClick={toggleFilters}
            >
              <FilterListIcon sx={{ fontSize: "32px", mr: "2px" }} />
              <Typography>
                {showFilters ? t("FILTERS.hide") : t("FILTERS.filter")}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
        }}
        component={motion.div}
        variants={filterVariants}
        animate={showFilters ? "open" : "closed"}
        transition={{ type: "tween" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "40px",
          }}
        >
          {filters.map((filter) => (
            <DynamicFilterComponent
              key={filter.id}
              value={selectedFilters[filter.id] ?? []}
              options={filter.options.map(option => ({ value: String(option.value), label: option.label }))}
              onChange={(value) => onChange(value, filter.id)}
              title={filter.title as string}
              id={filter.id}
              {...(filter.onInputChange ? { onInputChange: filter.onInputChange } : {})}
              {...(filter.isOptionsLoading ? { isLoading: filter.isOptionsLoading } : {})}
              {...(filter.onOpenChange ? { onOpenChange: filter.onOpenChange } : {})}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            <AnimatedButton
              size="medium"
              sx={{ textTransform: "none" }}
              variant="contained"
              disabled={!filtersChanged}
              onClick={handleOnApplyClick}
            >
              {t("FILTERS.apply")}
            </AnimatedButton>
            <AnimatedButton
              size="medium"
              sx={{ textTransform: "none" }}
              variant="outlined"
              onClick={handleOnClearClick}
            >
              {t("FILTERS.clearAll")}
            </AnimatedButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DynamicFilterContainer