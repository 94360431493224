import { Box, Typography } from "@mui/material";
import StereLogo from "assets/logos/StereLogo";
import { animateRouteProps } from "constants/common";
import AgencyDetailForm from "modules/Accounts/AgencyDetailForm";
import { useT } from "translation";
import * as Yup from "yup";


export const useAgencyValidationSchema = () => {
  const { t } = useT();
  const agencyDetailsValidationSchema = Yup.object().shape({
    cnpj: Yup.string().required(t("CNPJ_IS_REQUIRED")),
    licenseNumber: Yup.string(),
    agencyName: Yup.string().required(t("AGENCY_NAME_IS_REQUIRED")),
    agencyAddress: Yup.string().required(t("AGENCY_ADDRESS_IS_REQUIRED")),
  });
  return agencyDetailsValidationSchema;
};

const AdminOnBoarding = () => {
  const { t } = useT()
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={"column"}
      {...animateRouteProps}
    >
      <Box>
        <StereLogo />
      </Box>
      <Box
        textAlign="center"
        p={3}
        width="60%"
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: 700, marginBottom: "16px" }}
        >
          {t("AGENCY_DETAILS")}
        </Typography>
        <AgencyDetailForm />
      </Box>
    </Box>
  );
};

export default AdminOnBoarding;
