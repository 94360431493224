import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IChildAccount } from "modules/Accounts/types";
import { IMember } from "modules/Agents/types";
import { LSKeys, deleteFromLocalStorage } from "utils/ls.utils";
import { destroyCookies } from "utils/utils";

interface AuthState {
  portalAccount: PortalAccount;
  errorMessage: string;
  loading: boolean;
  passwordCreated: boolean;
  forgotPasswordEmailSent: boolean;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  authAccessToken: string;
  accounts: {
    allAccounts: IChildAccount[];
    activeAccounts: IChildAccount[];
    invitedAccounts: IChildAccount[];
    membershipRequest: IMember[];
    selectedAccount: IChildAccount | null;
    accessToken: string;
  };
}

const initialState: AuthState = {
  portalAccount: null,
  errorMessage: "",
  loading: false,
  passwordCreated: false,
  forgotPasswordEmailSent: false,
  accessToken: "",
  refreshToken: "",
  idToken: "",
  authAccessToken: "",
  accounts: {
    allAccounts: [],
    activeAccounts: [],
    invitedAccounts: [],
    membershipRequest: [],
    selectedAccount: null,
    accessToken: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<SignInPayload>) => {
      state.loading = true;
    },
    changePassword: (state, action: PayloadAction<ChangePasswordPayload>) => {
      state.loading = true;
    },
    signOut: (state) => {
      state.portalAccount = null;
      deleteFromLocalStorage(LSKeys.ACCESS_TOKEN);
      destroyCookies(['token']);
      state.authAccessToken = '';
      state.accounts.selectedAccount = null;
      state.accounts.allAccounts = [];
    },
    setUser: (state, action: PayloadAction<PortalAccount>) => {
      state.portalAccount = action.payload;
    },
    setCurrentUser: (state) => {
      state.loading = true;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setLoadingValue: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPasswordCreated: (state, action: PayloadAction<boolean>) => {
      state.passwordCreated = action.payload;
    },
    setForgotPasswordEmailSent: (state, action: PayloadAction<boolean>) => {
      state.forgotPasswordEmailSent = action.payload;
    },
    resetPassword: (state, action: PayloadAction<ResetPasswordPayload>) => {
      state.loading = true;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    setIdToken: (state, action: PayloadAction<string>) => {
      state.idToken = action.payload;
    },
    setAllUserAccounts: (state, action: PayloadAction<IChildAccount[]>) => {
      state.accounts.allAccounts = action.payload;
    },
    setUserActiveAccounts: (
      state,
      action: PayloadAction<IChildAccount[]>
    ) => {
      state.accounts.activeAccounts = action.payload;
    },
    setUserSelectedAccount: (
      state,
      action: PayloadAction<IChildAccount>
    ) => {
      state.accounts.selectedAccount = action.payload;
    },
    setUserInvitedAccount: (
      state,
      action: PayloadAction<IChildAccount[]>
    ) => {
      state.accounts.invitedAccounts = action.payload;
    },
    setUserAuthAccessToken: (state, action: PayloadAction<string>) => {
      state.authAccessToken = action.payload;
    },
    setMembershipRequest: (state, action: PayloadAction<Array<IMember>>) => {
      state.accounts.membershipRequest = action.payload
    }
  },
});

export const {
  signIn,
  signOut,
  setUser,
  changePassword,
  resetPassword,
  setPasswordCreated,
  setForgotPasswordEmailSent,
  setCurrentUser,
  setErrorMessage,
  setLoadingValue,
  setAccessToken,
  setRefreshToken,
  setIdToken,
  setAllUserAccounts,
  setUserSelectedAccount,
  setUserInvitedAccount,
  setUserAuthAccessToken,
  setUserActiveAccounts,
  setMembershipRequest
} = authSlice.actions;

export default authSlice.reducer;
