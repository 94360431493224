import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import LoginBg from "assets/images/login-img.png";
import StereLogo from "assets/logos/StereLogo";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { resetPasswordApi } from "modules/Auth/api";
import { IResetPassword } from "modules/Auth/types";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import * as Yup from "yup";

type ResetPasswordDecodedToken = {
  token: string;
  email: string;
};
const resetPasswordInitialValues = {
  password: "",
  confirmPassword: "",
};
function ResetPassword() {
  const customStyles = useSelector(customStylesSelector);
  const { t } = useT();
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const { setToast } = useContext(ToastCtx);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const requestResetPasswordMutation = useMutation<
    unknown,
    any,
    IResetPassword
  >({
    mutationFn: resetPasswordApi,
    onSuccess: () => {
      setToast({
        severity: "success",
        text: t("DONE_RESETTING_PASSWORD"),
      });
      navigate("/login");
    },
    onError: () => {
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("PASSWORD_REQUIRED"))
      .min(8, t("PASSWORD_VALIDATION.length"))
      .matches(/[0-9]/, t("PASSWORD_VALIDATION.numericChar"))
      .matches(/[A-Z]/, t("PASSWORD_VALIDATION.upperCaseLetter"))
      .matches(/[a-z]/, t("PASSWORD_VALIDATION.lowerCaseLetter"))
      .matches(/[^a-zA-Z0-9]/, t("PASSWORD_VALIDATION.specialCharacter")),

    confirmPassword: Yup.string()
      .required(t("CONFIRM_PASSWORD_REQUIRED"))
      .oneOf([Yup.ref("password")], t("Passwords must match")),
  });

  const [resetPasswordDecodedToken, setResetPasswordDecodedToken] =
    useState<ResetPasswordDecodedToken>(null);

  const handleSubmit = (value: typeof resetPasswordInitialValues) => {
    if (!resetPasswordDecodedToken || value.password !== value.confirmPassword)
      return;

    requestResetPasswordMutation.mutate({
      email: resetPasswordDecodedToken.email,
      token: resetPasswordDecodedToken.token,
      password: value.password,
    });
  };

  useEffect(() => {
    if (!token) {
      setToast({ severity: "error", text: t("INVALID_TOKEN") });
      navigate("/login");
      return;
    }
    try {
      const decodedToken = atob(token);
      const decodedInfo = JSON.parse(decodedToken) as ResetPasswordDecodedToken;
      setResetPasswordDecodedToken(decodedInfo);
    } catch {
      setToast({ severity: "error", text: t("INVALID_TOKEN") });
    }
  }, [navigate, setToast, t, token]);

  return (
    <Box {...animateRouteProps}>
      <Grid container>
        <Grid
          item
          md={6}
          sm={7}
          xs={12}
          sx={{
            height: "100vh",
            p: "20px",
            pt: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <StereLogo />
          </Box>
          <Box
            sx={{
              mt: "80px",
              minWidth: "80%",
            }}
          >
            <Typography
              sx={{
                mb: "16px",
                fontWeight: 600,
                textAlign: "center",
                fontSize: "18px",
                color: customStyles.muiTheme.palette.primary.main,
              }}
            >
              {t("RESET_PASSWORD")}
            </Typography>
            <Formik
              initialValues={resetPasswordInitialValues}
              validationSchema={resetPasswordSchema}
              onSubmit={handleSubmit}
              validateOnMount={true}
            >
              {({ isValid }) => {
                return (
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <Box>
                      <Field
                        as={TextField}
                        type={showPassword ? "text" : "password"}
                        label={t("PASSWORD_LABEL")}
                        name="password"
                        style={{
                          color: "black",
                          width: "100%",
                          borderRadius: "12px",
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "12px",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <ErrorMessage
                        className="error-message"
                        name="password"
                        component="div"
                      />
                    </Box>
                    <Box>
                      <Field
                        as={TextField}
                        type={showConfirmPassword ? "text" : "password"}
                        label={t("CONFIRM_PASSWORD")}
                        name="confirmPassword"
                        style={{
                          color: "black",
                          width: "100%",
                          borderRadius: "12px",
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "12px",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <ErrorMessage
                        className="error-message"
                        name="confirmPassword"
                        component="div"
                      />
                    </Box>

                    <AnimatedButton
                      sx={{ ml: "0px", textTransform: "none" }}
                      disabled={
                        !(isValid && Boolean(resetPasswordDecodedToken))
                      }
                      type={"submit"}
                      variant="contained"
                      isLoading={requestResetPasswordMutation.isPending}
                    >
                      {t("SUBMIT")}
                    </AnimatedButton>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sm={5}
          sx={{
            height: "100vh",
            position: "relative",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={LoginBg}
              alt="Login Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <Box
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                backgroundColor: customStyles.muiTheme.palette.primary.main,
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0.4,
              }}
            ></Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ResetPassword;
