// import { TextField } from "@mui/material";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";

import DynamicFilterContainer from "components/common/DynamicFilterContainer";
import useInsurerFilters from "modules/Appointments/useInsurerFilters";
import useProductFilters from "modules/Appointments/useProductFilters";
import { createContext, useMemo, useState } from "react";
import { useT } from "translation";

export enum ESortFilter {
  UPCOMING = 'UPCOMING',
  OUTDATED = 'OUTDATED',
  ACTIVE = 'ACTIVE',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}


const initialAppointmentFilter = { appointmentName: "", orderBy: "", order: 'desc', };

export const AppointmentFilterCtx = createContext<{ appointmentFilters: FilterParams, setAppointmentFilters: (filter: FilterParams) => void }>({
  appointmentFilters: {},
  setAppointmentFilters: () => { throw new Error("Function not implemented") }
});

function AppointmentFiltersProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useT();
  const { handleSearchInput, allProducts, isLoading: isProductsLoading } = useProductFilters()
  const { handleSearchInput: handleInsurerSearchInput, allInsurer, isLoading: isInsurerLoading } = useInsurerFilters()

  const filters = useMemo(() => {
    const appointmentStatusFilters: SelectOption[] = [
      { label: t("PENDING"), value: ESortFilter.PENDING },
      { label: t("APPROVED"), value: ESortFilter.APPROVED },
      { label: t("REJECTED"), value: ESortFilter.REJECTED }
    ]
    const allFilters: Filter[] = [
      {
        id: "status",
        title: t("STATUS"),
        options: appointmentStatusFilters,
      },
      {
        id: "product",
        title: t("PRODUCT"),
        options: allProducts || [],
        onInputChange: handleSearchInput,
        isOptionsLoading: isProductsLoading,
        onOpenChange: (value) => {
          if (!value) {
            handleSearchInput('')
          }
        }
      },
      {
        id: "insurer",
        title: t('INSURER'),
        options: allInsurer || [],
        onInputChange: handleInsurerSearchInput,
        isOptionsLoading: isInsurerLoading,
        onOpenChange: (value) => {
          if (!value) {
            handleInsurerSearchInput('')
          }
        }
      }
    ];
    return allFilters
  }, [t, allProducts, handleSearchInput, isProductsLoading, allInsurer, handleInsurerSearchInput, isInsurerLoading]);

  const [appointmentFilters, setAppointmentFilters] = useState<FilterParams>({
    ...initialAppointmentFilter,
  });

  const inputChange = (value: string, key: string) => {
    setAppointmentFilters({
      ...appointmentFilters,
      [key]: value,
    });
  };

  const onApply = (filterData: Record<string, string[]>) => {
    const obj = { ...appointmentFilters };
    for (const key in filterData) {
      obj[key] = filterData[key]
    }

    setAppointmentFilters(obj);
  };

  const onClear = () => {
    setAppointmentFilters(initialAppointmentFilter);
  };

  return (
    <>
      <Box my={"16px"}>
        <Box width={"100%"}>
          <DynamicFilterContainer
            onDateChange={(value, key) => inputChange(value.toString(), key)}
            title={null}
            filters={filters}
            onApply={onApply}
            onClear={onClear}
            onFiltersInput={(key: string, value: string) => {
              if (key === "product") {
                handleSearchInput(value)
              }
              if (key === "insurer") {
                handleInsurerSearchInput(value)
              }
            }}
            extraFilters={
              <Box sx={{ display: "flex", gap: "16px" }}>
                <TextField
                  type="name"
                  label={t("FILTER_APPOINTMENTS")}
                  value={appointmentFilters.intermediary_name}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                  onChange={(e) => inputChange(e.target.value, "intermediary_name")}
                />
              </Box>
            }
          />
        </Box>
      </Box>
      <AppointmentFilterCtx.Provider value={{ appointmentFilters, setAppointmentFilters }}>
        {children}
      </AppointmentFilterCtx.Provider>
    </>
  );
}

export default AppointmentFiltersProvider;
