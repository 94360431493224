import { Box, Tab, Tabs } from "@mui/material";
import AnimatedTabPanel from "components/custom/AnimatedTabPanel";
import { agentsPageDataAttr } from "constants/dataAttributes";
import AgentTable from "modules/Agents/AgentTable";
import AgentsFilterProvider from "modules/Agents/AgentsFilterProvider";
import InviteAgentsTable from "modules/Agents/InviteAgentsTable";
import React, { useMemo } from "react";
import { useT } from "translation";

function AgentsTabPanel() {
  const { t } = useT();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const tabs = useMemo(
    () => [
      {
        key: "myAgents",
        label: t("AGENTS_PAGE.agentsTabView.myAgents"),
        component: () => <AgentTable />,
        data_cy: agentsPageDataAttr.MY_AGENTS_TAB,
      },
      {
        key: "sentInvites",
        label: t("AGENTS_PAGE.agentsTabView.sentInvites"),
        component: () => <InviteAgentsTable />,
        data_cy: agentsPageDataAttr.INVITE_AGENTS_TAB,
      },
    ],
    [t]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          {tabs.map((tab) => {
            return (
              <Tab
                sx={{
                  textTransform: "none",
                }}
                key={tab.key}
                label={tab.label}
                data-cy={tab.data_cy}
              />
            );
          })}
        </Tabs>
      </Box>
      {tabs.map((tab, idx) => {
        return (
          <AnimatedTabPanel value={value} index={idx} key={tab.key} padding={0}>
            <AgentsFilterProvider
              hideStatusFilter={value === 1}
              hideNameFilter={value === 1}
            >
              <Box marginTop={"16px"}>
                <tab.component />
              </Box>
            </AgentsFilterProvider>
          </AnimatedTabPanel>
        );
      })}
    </Box>
  );
}

export default AgentsTabPanel;
