import { Box, Card, Typography } from "@mui/material";
import AnimatedButton from "components/custom/AnimatedButton";
import useDeleteMember from "modules/Agents/useDeleteMember";
import moment from "moment";
import { Link } from "react-router-dom";
import { useT } from "translation";
type Props = {
  description: string;
  timeStamp: Date;
  memberId: string;
  accountId: string;
};

function NotificationCard({
  description,
  timeStamp,
  memberId,
  accountId,
}: Props) {
  const { t } = useT();
  const { deleteMember, isDeleting } = useDeleteMember({});

  const handleDeleteInvite = () => {
    deleteMember(memberId, accountId);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        p: "16px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          marginBottom: "8px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
          }}
        >
          {description}
        </Typography>
        <Typography variant="body1">
          {moment(timeStamp).format("DD MMM YYYY")}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "12px" }}>
        <AnimatedButton
          variant="outlined"
          onClick={handleDeleteInvite}
          isLoading={isDeleting}
        >
          {t("NOTIFICATION_PAGE.decline")}
        </AnimatedButton>
        <Link to={`/accept-invite/${memberId}`}>
          <AnimatedButton variant="contained">
            {t("NOTIFICATION_PAGE.accept")}
          </AnimatedButton>
        </Link>
      </Box>
    </Card>
  );
}

export default NotificationCard;
