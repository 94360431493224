import { useQuery } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import { CORX, PORTAL_NAME } from "config";
import { ClientProduct } from "customTypes/ClientProducts";
import { fetchMemberAppointments } from "modules/Appointments/api";
import { IDistributionsResp } from "modules/Appointments/types";
import { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAvailableDistributions } from "store/features/appointmentSlice";
import { setAvailableProducts } from "store/features/clientSlice";
import { RootState } from "store/store";
import { useT } from "translation";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";

function useAvailableAppointments() {
  const selectedAccount = useSelector(
    (state: RootState) => state.auth.accounts?.selectedAccount
  );
  const dispatch = useDispatch();
  const { setToast } = useContext(ToastCtx);
  const accountId = selectedAccount?.id || "";
  const memberId = selectedAccount?.member?.id || "";
  const { t } = useT();
  const accessToken = getFromLocalStorage(LSKeys.ACCESS_TOKEN);

  const { data, isLoading, isError, isSuccess } = useQuery<IDistributionsResp>({
    queryFn: () => fetchMemberAppointments.queryFn({ memberId, accountId }),
    queryKey: fetchMemberAppointments.queryKey({ memberId, accountId }),
    enabled: Boolean(accountId) && Boolean(memberId) && Boolean(accessToken) && PORTAL_NAME === CORX,
  });

  const distributionAccess = useMemo(() => {
    return (data?.distribution_accesses || []).filter(dist => dist?.appointment?.status === "approved");
  }, [data?.distribution_accesses]);

  const carriers = useMemo(() => {
    const productsCarrierMap: Record<string, Array<string>> = {};
    distributionAccess.forEach(d => {
      const productIdentifier = d.appointment?.insurer_product?.insurance_product || '';
      const distCarrier = d.appointment?.insurer_product?.carrier || '';
      if (productIdentifier && distCarrier) {
        if (Array.isArray(productsCarrierMap[productIdentifier])) {
          productsCarrierMap[productIdentifier].push(distCarrier);
        } else {
          productsCarrierMap[productIdentifier] = [distCarrier]
        }
      }
    })

    return productsCarrierMap;
  }, [distributionAccess])

  useEffect(() => {
    if (isError) {
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    }
  }, [isError, setToast, t]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAvailableDistributions(distributionAccess));
      const transformedAvailableProducts: Map<any, ClientProduct> = new Map();
      distributionAccess.forEach(dist => {
        const pId = dist.appointment.insurer_product.insurance_product;
        const pLob = dist.appointment.insurer_product.line_of_business;
        const pName = dist.appointment.insurer_product.name;

        const product = {
          product: {
            product_identifier: pId,
            product_category: pLob,
            product_name: pName,
            translations: null,
            ready_to_use: true
          },
          carrier: carriers[pId],
        } as ClientProduct;

        const productKey = `${pId}_${pLob}_${pName}`;

        if (!transformedAvailableProducts.has(productKey)) {
          transformedAvailableProducts.set(productKey, product);
        }
      });

      const productsArr = Array.from(transformedAvailableProducts.values());
      dispatch(setAvailableProducts(productsArr));

    }
  }, [carriers, dispatch, distributionAccess, isSuccess]);

  return { isAppointmentsLoading: isLoading }
}

export default useAvailableAppointments;