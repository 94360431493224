import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { useMutation } from "@tanstack/react-query";
import LoginBg from "assets/images/login-img.png";
import StereLogo from "assets/logos/StereLogo";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { loginPageDataAttr } from "constants/dataAttributes";
import { Link, useNavigate } from "react-router-dom";
import { setUserAuthAccessToken } from "store/features/authSlice";
import { customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { LSKeys, saveToLocalStorage } from "utils/ls.utils";
import { loginUserApi, loginUserWithFairfaxTokenApi } from "./api";
import { IFairfaxUserLogin, IFairfaxUserLoginResponse, IUserLogin, IUserLoginResponse } from "./types";
import { FAIRFAX, PORTAL_NAME } from "config";
import { getCookie } from "utils/utils";

const Login = () => {
  const { t } = useT();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = getCookie("token") || searchParams.get("token");
  const redirectUri = searchParams.get("redirect_uri");
  const { errorMessage } = useSelector((state: RootState) => state.auth);
  // const accessToken = getFromLocalStorage(LSKeys.ACCESS_TOKEN);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("INVALID_EMAIL")).required(t("EMAIL_REQUIRED")),
    password: Yup.string().required(t("PASSWORD_REQUIRED")),
  });
  const { setToast } = useContext(ToastCtx);

  const initialValues = {
    email: "",
    password: "",
  };
  const customStyles = useSelector(customStylesSelector);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => setShowPassword((show) => !show);

  // Mutation for login with Fairfax token
  const loginUserWithFairfaxTokenMutation = useMutation<IFairfaxUserLoginResponse, any, IFairfaxUserLogin>({
    mutationFn: loginUserWithFairfaxTokenApi,
    onSuccess: (loggedInUser) => {
      saveToLocalStorage(LSKeys.ACCESS_TOKEN, loggedInUser.access_token);
      saveToLocalStorage(LSKeys.REDIRECT_URI, redirectUri);
      dispatch(setUserAuthAccessToken(loggedInUser.access_token));
      navigate("/home/applications");
    },
    onError: ({ error }) => {
      const errorCode = error?.code || "";
      if (errorCode === "required_field_missing") {
        setToast({
          severity: "error",
          text: t("LOGIN_REQUIRED_FIELD_MISSING"),
        });
      }
      if (errorCode === "token_not_found") {
        setToast({
          severity: "error",
          text: t("LOGIN_TOKEN_NOT_FOUND"),
        });
      }
      if (errorCode === "external_api_error") {
        setToast({
          severity: "error",
          text: t("EXTERNAL_API_ISSUE"),
        });
      }
    },
  });

  const loginUserMutation = useMutation<IUserLoginResponse, any, IUserLogin>({
    mutationFn: loginUserApi,
    onSuccess: (loggedInUser) => {
      saveToLocalStorage(LSKeys.ACCESS_TOKEN, loggedInUser.access_token);
      dispatch(setUserAuthAccessToken(loggedInUser.access_token));
    },
    onError: ({ error }) => {
      if (error.code === "user_not_exists") {
        return setToast({
          severity: "warning",
          text: t("USER_NOT_EXIST"),
        });
      }
      // change below to unable to login
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  useEffect(() => {
    if (token && PORTAL_NAME === FAIRFAX) {
      loginUserWithFairfaxTokenMutation.mutate({ token });
    }
  }, [token]);

  const handleSubmit = async (values) => {
    try {
      await loginUserMutation.mutateAsync({
        email: values.email,
        password: values.password,
      });
    } catch (error) {
      // Handle error
    }
  };

  return (
    <Box {...animateRouteProps}>
      <Grid className="loginForm" container>
        <Grid
          item
          md={6}
          sm={7}
          xs={12}
          sx={{
            height: "100vh",
            p: "20px",
            pt: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <StereLogo />
          </Box>
          <Box sx={{ mt: "80px" }}>
            <Typography
              sx={{
                mb: "10px",
                fontWeight: 600,
                textAlign: "center",
                fontSize: "18px",
                color: customStyles.muiTheme.palette.primary.main,
              }}
            >
              {t("SIGN_IN")}
            </Typography>
            <Typography
              sx={{
                mb: "30px",
                fontWeight: 400,
                textAlign: "center",
                fontSize: "14px",
                color: customStyles.muiTheme.palette.text.secondary,
              }}
            >
              {t("SIGN_IN_MESSAGE")}
            </Typography>
            <Typography
              sx={{
                mb: "30px",
                fontWeight: 400,
                textAlign: "center",
                fontSize: "14px",
                color: "red",
              }}
            >
              {errorMessage}
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount={true}
              onSubmit={handleSubmit}
            >
              {({ isValid }) => (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Field
                      as={TextField}
                      type="email"
                      label={t("EMAIL_LABEL")}
                      name="email"
                      style={{
                        width: "100%",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "12px",
                        },
                      }}
                      data-cy={loginPageDataAttr.LOGIN_EMAIL}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="email"
                      component="div"
                    />
                  </Box>

                  <Box
                    sx={{
                      mt: "30px",
                    }}
                  >
                    <Field
                      as={TextField}
                      type={showPassword ? "text" : "password"}
                      label={t("PASSWORD_LABEL")}
                      name="password"
                      data-cy={loginPageDataAttr.LOGIN_PASSWORD}
                      style={{
                        color: "black",
                        width: "100%",
                        borderRadius: "12px",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "12px",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="password"
                      component="div"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: "5px",
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label={
                        <Typography
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          {t("REMEMBER_ME")}
                        </Typography>
                      }
                    />
                    <Link to='/forgot-password'>
                      <Typography
                        px={2}
                        sx={{
                          color: customStyles.input.color,
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontSize: "14px",
                          textUnderlineOffset: "3px",
                        }}
                      >
                        {t("FORGOT_PASSWORD")}
                      </Typography>
                    </Link>
                  </Box>

                  <AnimatedButton
                    sx={{ ml: "0px", mt: "30px", textTransform: "none" }}
                    disabled={!isValid}
                    type={"submit"}
                    data_cy={loginPageDataAttr.LOGIN_BUTTON}
                    isLoading={loginUserMutation.isPending}
                    variant="contained"
                  >
                    {t("SIGN_IN")}
                  </AnimatedButton>
                  <Typography
                    variant="body1"
                    sx={{ my: "16px", textAlign: "center" }}
                  >
                    {t("OR")}
                  </Typography>
                  <Link to={"/register"}>
                    <AnimatedButton
                      data_cy={loginPageDataAttr.GO_TO_REGISTER_PAGE}
                      variant="outlined"
                      sx={{ ml: "0px", textTransform: "none", width: "100%" }}
                    >
                      {t("REGISTER")}
                    </AnimatedButton>
                  </Link>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sm={5}
          sx={{
            height: "100vh",
            position: "relative",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={LoginBg}
              alt="Login Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <Box
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                backgroundColor: customStyles.muiTheme.palette.primary.main,
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0.4,
              }}
            ></Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
