import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SurveyComponent from "components/SurveyJS";
import CustomerDetailsPopup from "components/common/Header/CustomerDetailsPopup";
import SectionInfo from "components/common/SectionInfo/SectionInfo";
import CircularProgressLoader from "components/loader/Loader";
import { FAIRFAX, IS_BROKER_PORTAL, PORTAL_NAME } from "config";
import { animateRouteProps } from "constants/common";
import { setLoaderText } from "store/features/loaderSlice";
import {
  createSiaApplication,
  fetchSIAApplicationId,
  updateSIAApplication,
} from "store/features/surveyJsSlice";
import { applicationStepLoadingValueSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import InsuranceInfo from "./InsuranceInfo";

const Application = () => {
  const dispatch = useDispatch();
  const { t } = useT();

  const loadingState = useSelector(applicationStepLoadingValueSelector);
  const surveyJson = useSelector(
    (state: RootState) => state.surveyJs?.siaApplication?.survey_json
  );
  const { siaApplicationId, updatedSiaApplication } = useSelector(
    (state: RootState) => state.surveyJs
  );
  const { customerDetails } = useSelector(
    (state: RootState) => state.customerDetails
  );

  // Need to handle for other portals if they use popup for email-id
  const [isCustomerDetailsPopupOpen, setIsCustomerDetailsPopupOpen] = useState(
    IS_BROKER_PORTAL &&
      !siaApplicationId &&
      PORTAL_NAME === FAIRFAX &&
      !customerDetails?.email &&
      !updatedSiaApplication?.params?.business_email
  );

  const onClose = () => {
    if (customerDetails?.email) {
      setIsCustomerDetailsPopupOpen(false);
    }
  };

  const { availableProducts } = useSelector((state: RootState) => state.client);

  useEffect(() => {
    if (!surveyJson && availableProducts?.length) {
      dispatch(setLoaderText(t("FETCHING_APPLICATION")));
      if (IS_BROKER_PORTAL) {
        if (siaApplicationId) {
          dispatch(fetchSIAApplicationId());
        } else if (!isCustomerDetailsPopupOpen) {
          dispatch(createSiaApplication());
        }
      } else {
        dispatch(fetchSIAApplicationId());
      }
    }
  }, [availableProducts]);

  useEffect(() => {
    if (siaApplicationId) {
      if (IS_BROKER_PORTAL && PORTAL_NAME === FAIRFAX) {
        dispatch(updateSIAApplication());
      }
    }
  }, [dispatch, siaApplicationId, surveyJson]);

  if (loadingState) {
    return <CircularProgressLoader />;
  }
  return (
    <>
      <Box {...animateRouteProps}>
        <Grid container>
          <Grid
            item
            md={9}
            lg={9}
            sm={12}
            xs={12}
            sx={{
              pl: { xs: "15px", sm: "20px", md: "20px", lg: "20px" },
              pr: { xs: "15px", sm: "20px", md: "20px", lg: "20px" },
              width: { xs: "100%", sm: "100%" },
              overflow: "hidden",
            }}
          >
            <SectionInfo
              subTitle={
                PORTAL_NAME !== FAIRFAX
                  ? t("APPLICATION_PAGE_DESCRIPTION_PRIMARY")
                  : ""
              }
              description={t("APPLICATION_PAGE_DESCRIPTION_SECONDARY")}
            />
            {surveyJson && (
              <SurveyComponent surveyJson={surveyJson} key="user-survey" />
            )}
          </Grid>
          <Grid
            item
            md={2}
            lg={2}
            sm={0}
            xs={0}
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
            }}
          >
            <InsuranceInfo />
          </Grid>
        </Grid>
      </Box>
      <CustomerDetailsPopup
        isOpen={isCustomerDetailsPopupOpen}
        setIsOpen={onClose}
      />
    </>
  );
};

export default Application;
