import React, { PropsWithChildren, useCallback, useMemo } from "react";

import { COUNTRY } from "config";
import { CountryToLanguageMapping } from "utils/utils";
import TranslationEn from "./TranslationEn";
import TranslationEs from "./TranslationEs";
import TranslationPt from "./TranslationPt";
import { TranslationKeys } from "./types";

const pt = new TranslationPt();
const en = new TranslationEn();
const es = new TranslationEs();

const langTranlationKey = {
  pt: pt,
  en: en,
  es: es,
};

type TranslationState = { lang: string; keys: TranslationKeys };

export type Languages = "en" | "pt" | "es";

const TranslationContext = React.createContext<TranslationState | undefined>(
  undefined
);

export type AvailableLanguages = "en" | "pt";

const TranslationUpdaterContext = React.createContext<
  React.Dispatch<React.SetStateAction<Languages>> | undefined
>(undefined);

const TranslationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [lang, setLang] = React.useState(CountryToLanguageMapping[COUNTRY]);

  const value = useMemo(() => {
    return {
      lang,
      keys: langTranlationKey[lang],
    };
  }, [lang]);

  return (
    <TranslationContext.Provider value={value}>
      <TranslationUpdaterContext.Provider value={setLang}>
        {children}
      </TranslationUpdaterContext.Provider>
    </TranslationContext.Provider>
  );
};

const useSetTranslationLang = () => {
  const setLang = React.useContext(TranslationUpdaterContext);
  if (setLang === undefined) {
    throw new Error(
      "useSetTranslationLang must be used within a TranslationProvider"
    );
  }

  return setLang;
};

const useT = () => {
  const translationState = React.useContext(TranslationContext);
  const t = useCallback(
    (id, data?: any): string => {
      const parts = id?.split(".");
      let textItem = translationState?.keys?.[parts?.[0]];
      parts?.splice(0, 1);
      for (const part of parts ?? []) {
        textItem = textItem?.[part];
      }
      const str: string = (textItem as string) || id;
      return textItem instanceof Function ? textItem(data) : str;
    },
    [translationState]
  );

  return { t, lang: translationState?.lang };
};

export { TranslationProvider, useSetTranslationLang, useT };
