import { useQuery } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import { debounce } from "lodash";
import { getInsurers } from "modules/Appointments/api";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useT } from "translation";

const useInsurerFilters = () => {
  const { setToast } = useContext(ToastCtx)
  const { t } = useT();
  const [searchInput, setSearchInput] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchInput = useCallback(
    debounce((value: string) => setSearchInput(value), 300),
    [setSearchInput]
  );
  const accountId = useSelector(
    (state: RootState) => state.auth.accounts?.selectedAccount?.id
  );

  const {
    data: insurerData,
    isError: isInsurersLoadingError,
    isLoading
  } = useQuery({
    queryFn: () => getInsurers.queryFn(searchInput, accountId),
    queryKey: getInsurers.queryKey(searchInput, accountId),
    enabled: Boolean(accountId)
  });

  useEffect(() => {
    if (isInsurersLoadingError) {
      setToast({
        text: t('ERROR_FETCHING_INSURER'),
        severity: 'error',
      });
    }
  }, [isInsurersLoadingError, setToast, t]);

  const allInsurer = useMemo(
    () =>
      (insurerData?.data || []).map((i) => ({
        label: i.name,
        value: i.name,
      })),
    [insurerData?.data]
  );
  return { allInsurer, handleSearchInput: debouncedSetSearchInput, isLoading }

}

export default useInsurerFilters;