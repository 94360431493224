import { Box } from "@mui/material";
import OutletHeader from "components/Home/Common/OutletHeader";
import AnimatedButton from "components/custom/AnimatedButton";

import { animateRouteProps } from "constants/common";
import AppointmentFiltersProvider from "modules/Appointments/AppointmentFiltersProvider";
import AppointmentsTable from "modules/Appointments/AppointmentsTable";
import RequestAppointment from "modules/Appointments/RequestAppointment";
import { useState } from "react";
import { useT } from "translation";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useIsAdmin from "modules/Agents/useIsAdmin";

function AppointmentsPage() {
  const { t } = useT();
  const isAdmin = useIsAdmin();
  const [isRequestAppointmentVisible, setIsRequestAppointmentVisible] = useState(false);
  const closeRequestAppointment = () => setIsRequestAppointmentVisible(false)
  if (isRequestAppointmentVisible) {
    return <Box {...animateRouteProps} sx={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", width: "100%", gap: "24px" }}>
      <AnimatedButton
        variant="text"
        startIcon={<ArrowBackIcon />}
        onClick={() => setIsRequestAppointmentVisible(false)}>
        {t("GO_BACK")}
      </AnimatedButton>
      <Box width={"100%"}>

        <RequestAppointment closeRequestAppointment={closeRequestAppointment} />
      </Box>
    </Box>
  }
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "12px",
        }}
        {...animateRouteProps}
      >
        <OutletHeader
          title={t("MY_APPOINTMENTS")}
          actionBtn={
            isAdmin ? <AnimatedButton variant="contained"
              onClick={() => setIsRequestAppointmentVisible(true)}
            >
              {t("REQUEST_APPOINTMENT")}
            </AnimatedButton> : null}
        />
      </Box>
      <Box>
        <AppointmentFiltersProvider>
          <AppointmentsTable />
        </AppointmentFiltersProvider>
      </Box>
    </Box>
  );
}

export default AppointmentsPage;
