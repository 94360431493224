import { useFlags } from 'flagsmith/react';

function useDashboardStartScreen() {
  const { v1_dashboard } = useFlags(['v1_dashboard']);
  const dashboardStartScreen = v1_dashboard.enabled
    ? '/home/dashboard'
    : '/home/applications';

  return dashboardStartScreen;
}

export default useDashboardStartScreen;
