import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import { ERole } from "modules/Accounts/types";
import { fetchUserProfileDetails, updateUserProfileDetails } from "modules/Profile/api";
import { IUpdateUserDetails } from "modules/Profile/types";
import { useContext } from "react";
import { useT } from "translation";


function useUpdateRole() {
  const { t } = useT()
  const queryClient = useQueryClient();
  const { setToast } = useContext(ToastCtx);
  const onProfileUpdateSuccess = (data) => {
    setToast({
      severity: "success",
      text: t("PROFILE_UPDATE"),
    });
    queryClient.invalidateQueries({
      queryKey: fetchUserProfileDetails.queryKey,
    });
  };

  const onProfileUpdateFailure = (err) => {
    const errorCode = err?.error?.code || "";
    if (errorCode === "invalid_auth_key") {
      setToast({
        severity: "error",
        text: t("INVALID_AUTH_KEY"),
      });
      return;
    }
    setToast({
      severity: "error",
      text: t("SOMETHING_WENT_WRONG"),
    });
  };

  const updateUserDetailsMutation = useMutation<unknown, any, IUpdateUserDetails>({
    mutationFn: updateUserProfileDetails,
  });

  const changeToAdmin = (callback?: () => void) => {
    updateUserDetailsMutation.mutateAsync({ meta: { roles: [ERole.ADMIN, ERole.AGENT] } }).then((data) => {
      onProfileUpdateSuccess(data);
      callback?.()
    }).catch(onProfileUpdateFailure)
  }
  const changeToAgent = (callback?: () => void) => {
    updateUserDetailsMutation.mutateAsync({ meta: { roles: [ERole.AGENT] } }).then((data) => {
      onProfileUpdateSuccess(data);
      callback?.()
    }).catch(onProfileUpdateFailure)
  }
  return { changeToAdmin, changeToAgent, isLoading: updateUserDetailsMutation.isPending }
}

export default useUpdateRole;