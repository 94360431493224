import { IAgencyDetails } from "modules/Accounts/types";
import api from "utils/api.utils";

export const createAccountAPI = async (
  account: IAgencyDetails
): Promise<any> => {
  try {
    const response = await api.post("accounts/child-accounts", account);
    return response;
  } catch (err) {
    throw err;
  }
};

export const generateKeysForChildAccountAPI = async (
  accountId: string
): Promise<any> => {
  try {
    const response = await api.post(`accounts/${accountId}/generate-keys`, {
      name: "Live Keys",
      mode: "live",
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const sendMemberShipRequestAPI = async (
  adminEmail: string
): Promise<unknown> => {
  try {
    const response = await api.post('members/request', {
      email: adminEmail
    });
    return response
  } catch (err) {
    throw err
  }
}