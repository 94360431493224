import { Typography } from "@mui/material";
import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import paymentApiUtil from "api/payments";
import { stripeConfirmAPI } from "api/siaApplication";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderText } from "store/features/loaderSlice";
import { setLoadingValue } from "store/features/paymentSlice";
import { nextStep } from "store/features/stepperSlice";
import { clientSelector, customerSelector } from "store/selectors/selectors";
import { useT } from "translation";

type WalletPayPaymentProps = {
  paymentData: PaymentPayload[];
  setIsLoading: (val: boolean) => void;
  setIsPaymentFailed: (val: boolean) => void;
};

const StripeWalletPayments = ({
  paymentData,
  setIsLoading,
  setIsPaymentFailed,
}: WalletPayPaymentProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const customerId = useSelector(customerSelector);
  const dispatch = useDispatch();
  const { t } = useT();
  const { clientAPIKey } = useSelector(clientSelector);

  useEffect(() => {
    if (!stripe || !elements || !paymentData[0]?.premium) return;
    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: t("Payment"),
        amount: Math.round(paymentData[0]?.premium * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on("paymentmethod", async (e) => {
      try {
        const paymentMethodId = e.paymentMethod.id;
        const { payerName: name, payerEmail: email } = e;
        if (!name || !email) {
          return;
        }
        dispatch(setLoadingValue(true));
        dispatch(setLoaderText(t("PROCESSING_PAYMENT")));
        setIsLoading(true);

        const {
          results: { clientSecret, invoiceId },
        } = await paymentApiUtil.generateClientSecret({
          paymentMethodId,
          name,
          email,
          description: "-",
          customer_id: customerId,
          payments: (paymentData || []).map((payment) => ({
            ...payment,
            carrier: payment?.carrier?.toLowerCase(),
          })),
        });

        const { paymentIntent, error } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          { handleActions: false }
        );
        if (error) {
          setIsPaymentFailed(true);
          e.complete("fail");
          return;
        }
        e.complete("success");
        if (paymentIntent.status === "requires_action") {
          stripe.confirmCardPayment(clientSecret);
        }
        try {
          if (invoiceId) {
            await paymentApiUtil.markInvoicePaid(invoiceId);
          }
          const stripeConfirmRes = await stripeConfirmAPI(clientAPIKey, {
            payment_type:
              paymentData[0]?.paymentType === "year" ? "annual" : "monthly",
            premium: paymentData[0]?.premium,
            premium_currency: paymentData[0]?.currency,
            quote_id: paymentData[0]?.quote_id,
            transaction_id: paymentIntent.id,
            transaction_fee: paymentData[0]?.transaction_fee,
            applicant_name: paymentData[0]?.applicant_name,
          });
          sessionStorage.setItem("tId", paymentIntent.id);
          sessionStorage.setItem(
            "policyId",
            stripeConfirmRes?.data?.results?.policy_id ?? ""
          );
          if (stripeConfirmRes?.data?.results?.response?.template_slug) {
            sessionStorage.setItem(
              "template_slug",
              stripeConfirmRes?.data?.results?.response?.template_slug
            );
          }
          dispatch(nextStep());
        } catch (err) {
          setIsPaymentFailed(true);
        }
      } catch (error) {
        setIsPaymentFailed(true);
        e.complete("fail");
        console.error(error);
      } finally {
        setIsLoading(false);
        dispatch(setLoadingValue(false));
        dispatch(setLoaderText(null));
      }
    });
  }, [
    clientAPIKey,
    customerId,
    dispatch,
    elements,
    paymentData,
    setIsLoading,
    setIsPaymentFailed,
    stripe,
    t,
  ]);

  return (
    paymentRequest && (
      <>
        <PaymentRequestButtonElement options={{ paymentRequest }} />
        <Typography my={"4px"}>{t("or")}</Typography>
      </>
    )
  );
};

export default StripeWalletPayments;
