import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { COUNTRY, IS_HOSTED_INSURANCE } from "config";
import { animateRouteProps } from "constants/common";
import { customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";

interface EsignSuccessI {
  brokerEmail: string;
}

const EsignSuccess: React.FC<EsignSuccessI> = ({ brokerEmail }) => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const productIdentifier = useSelector(
    (state: RootState) =>
      state.client.availableProducts[0]?.product?.product_identifier
  );
  const isMsig = COUNTRY === "sg" && productIdentifier === "PET";
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "80vh",
      }}
      {...animateRouteProps}
    >
      <Box
        data-cy="success-message"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: "auto",
        }}
      >
        <Box
          sx={{
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            width: "100px",
            background: customStyles.stepper.backgroundColor,
          }}
        >
          <CheckIcon
            data-cy="success-check-icon"
            style={{
              color: customStyles.stepper.step.color,
              fontSize: "75px",
            }}
          />
        </Box>
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.primary.main,
            fontSize: "24px",
            fontWeight: 700,
            mt: "30px",
          }}
        >
          Documents signed successfully
        </Typography>
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.text.tertiary,
            fontSize: "20px",
            fontWeight: 500,
            textAlign: "center",
            mt: "24px",
          }}
        >
          {t("SUCCESS_PAGE_CONTENT.line1")}
        </Typography>
      </Box>
      {IS_HOSTED_INSURANCE && (
        <Box
          sx={{
            p: "15px",
            borderRadius: "12px",
            mb: "20px",
            border: "2px solid",
            borderColor: customStyles.muiTheme.palette.primary.main,
            backgroundColor: `${customStyles.muiTheme.palette.primary.main}20`,
            maxWidth: "80%",
          }}
        >
          <Typography
            sx={{
              color: customStyles.muiTheme.palette.text.tertiary,
              fontSize: "14px",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {t("SUCCESS_PAGE_CONTENT.line2")}
            {/* Need to change this */}
            <span style={{ color: customStyles.muiTheme.palette.text.primary }}>
              {brokerEmail}
            </span>
            {t("SUCCESS_PAGE_CONTENT.line3")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EsignSuccess;
