import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  debounce,
  Autocomplete,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useT } from "translation";
import AnimatedButton from "components/custom/AnimatedButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getInsurerProducts, getInsurers, requestAppointments } from "modules/Appointments/api";
import { useSelector } from "react-redux";
import { ToastCtx } from "components/Toast/ToastProvider";
import { RootState } from "store/store";

interface FormValues {
  insurer: string;
  product: string;
}

type Props = {
  closeRequestAppointment: () => void
}

const RequestAppointment: React.FC<Props> = ({ closeRequestAppointment }) => {
  const { t } = useT();
  const [isConfirmSubmissionOpen, setIsConfirmSubmissionOpen] = useState(false);
  const { setToast } = useContext(ToastCtx);
  const [formValues, setFormValues] = useState<typeof initialValues>({ insurer: "", product: "" });
  const accountId = useSelector(
    (state: RootState) => state.auth.accounts?.selectedAccount?.id
  );
  const requestAppointmentMutation = useMutation({
    mutationFn: (productId: string) => requestAppointments.mutationFn({ productId, accountId }),
    onSuccess: () => {
      setToast({ severity: "success", text: "Appointment created" });
      setIsConfirmSubmissionOpen(false);
      closeRequestAppointment();
    },
    onError: ({ error }: { error: { code: string } }) => {
      if (error.code === "appointment_already_exists") {
        return setToast({ severity: "error", text: t("APPOINTMENT_ALREADY_EXISTS") });
      }
      setToast({ severity: "error", text: t("SOMETHING_WENT_WRONG") })
    }
  });
  const [searchInsurerName, setSearchInsurerName] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetInsurerSearchInput = useCallback(
    debounce((value: string) => setSearchInsurerName(value), 300),
    [setSearchInsurerName]
  );

  const {
    data: insurerData,
    isLoading: isInsurersLoading,
    isError: isInsurerLoadingError,
  } = useQuery({
    queryFn: () => getInsurers.queryFn(searchInsurerName, accountId),
    queryKey: getInsurers.queryKey(searchInsurerName, accountId),
    enabled: Boolean(accountId)
  });

  const {
    data: productsData,
    isLoading: isProductsLoading,
    isError: isProductsLoadingError,
  } = useQuery({
    queryFn: () => getInsurerProducts.queryFn(formValues.insurer, accountId),
    queryKey: getInsurerProducts.queryKey(formValues.insurer, accountId),
    enabled: Boolean(formValues.insurer) && Boolean(accountId)
  });

  const allInsurers = insurerData?.data.map((d, index) => ({ label: d.name, value: d.id, key: d.id })) || []

  const allProducts = useMemo(() => {
    return productsData?.data.map(d => ({ label: d.name, value: d.id })) || []
  }, [productsData?.data])

  const initialValues: FormValues = {
    insurer: '',
    product: '',
  };

  const validationSchema = Yup.object({
    insurer: Yup.string().required(t('INSURER_REQUIRED')),
    product: Yup.string().required(t('PRODUCT_REQUIRED')),
  });

  const handleInsurerChange = async (
    selectedInsurer: string,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setFieldValue('insurer', selectedInsurer);
    setFieldValue('product', '');
    setFormValues({ insurer: selectedInsurer, product: '' })
  };

  const handleSubmit = (values: typeof initialValues) => {
    setFormValues(values);
    setIsConfirmSubmissionOpen(true);
  }
  const handleConfirmClick = () => {
    requestAppointmentMutation.mutate(formValues.product)
  }

  useEffect(() => {
    if (isInsurerLoadingError) {
      setToast({ severity: "error", text: t("ERROR_LOADING_INSURER_DATA") })
    }
  }, [isInsurerLoadingError, setToast, t])

  useEffect(() => {
    if (isProductsLoadingError) {
      setToast({ severity: "error", text: t("ERROR_LOADING_INSURER_PRODUCTS_DATA") })
    }
  }, [isProductsLoadingError, setToast, t])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, setFieldValue, isValid, errors }) => {
          return <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={allInsurers}
                  filterOptions={(x) => x}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  isOptionEqualToValue={(opt, value) => opt.label === value.label}

                  onChange={(_e, option) => handleInsurerChange(option?.value || '', setFieldValue)}
                  getOptionLabel={(option) => option.label}
                  sx={{ width: '100%' }}
                  clearOnBlur={false}
                  loading={isInsurersLoading}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isInsurersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      label={t("INSURER_LABEL")}
                      onChange={e => {
                        debouncedSetInsurerSearchInput(e?.target?.value || '')
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth disabled={!formValues.insurer}>
                  <InputLabel id="product-select">{t("PRODUCT")}</InputLabel>
                  <Select
                    disabled={isProductsLoading || allProducts.length === 0 || isProductsLoadingError}
                    labelId="product-select"
                    id="product-select"
                    label={t("PRODUCT")}
                    onChange={(e) => {
                      const product = e.target.value as string;
                      setFieldValue('product', product);
                      setFormValues({ ...formValues, product })
                    }}
                    value={formValues.product}
                  >
                    {allProducts.map(product => {
                      return <MenuItem value={product.value}>{product.label}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <AnimatedButton type="submit" variant="contained" color="primary" disabled={!isValid}>
                  {t("SUBMIT")}
                </AnimatedButton>
              </Grid>
            </Grid>
          </Form>
        }}
      </Formik >
      <Dialog
        open={isConfirmSubmissionOpen}
        onClose={() => setIsConfirmSubmissionOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography color="primary" variant="h6" fontWeight="bold">
            {t("CONFIRM_SUBMISSION_TITLE")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography color="primary" marginBottom="16px">
            {t("CONFIRM_SUBMISSION_MESSAGE")}
          </Typography>
          <Box sx={{ display: "flex", gap: "12px" }}>
            <AnimatedButton variant="outlined" onClick={() => setIsConfirmSubmissionOpen(false)}>{t('CANCEL')}</AnimatedButton>
            <AnimatedButton variant="contained" isLoading={requestAppointmentMutation.isPending} onClick={handleConfirmClick}>{t("SUBMIT")}</AnimatedButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RequestAppointment;
