import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";
import { IS_HOSTED_INSURANCE } from "config";
import { RootState } from "store/store";
import { LSKeys, deleteFromLocalStorage, getFromLocalStorage } from "utils/ls.utils";
import { RedirectLoader } from "./RedirectLoader";




const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { portalAccount, loading } = useSelector(
    (state: RootState) => state.auth
  );

  const isUserAuthenticated = !!getFromLocalStorage(LSKeys.ACCESS_TOKEN);
  const redirect_uri = getFromLocalStorage(LSKeys.REDIRECT_URI);


  if (isUserAuthenticated) {
    return <>{children}</>;
  }

  if (!isUserAuthenticated && redirect_uri && (redirect_uri.startsWith("http") || redirect_uri.startsWith("https"))) {
    deleteFromLocalStorage(LSKeys.REDIRECT_URI);
    setTimeout(() => {
      window.location.href = redirect_uri;
    }, 500)
    return <RedirectLoader />;
  }

  if (IS_HOSTED_INSURANCE || loading) {
    return <>{children}</>;
  }

  if (portalAccount) {
    if (portalAccount.is_temporary_password) {
      return (
        <Navigate
          to="/create-new-password"
          state={{ from: location }}
          replace
        />
      );
    } else {
      return <Box>{children}</Box>;
    }
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default ProtectedRoute;
