import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Typography } from "@mui/material";

import { useUserProfile } from "hooks/useUserProfile";
import { useT } from "translation";
import { AppContentContainer } from "../AppContentContainer";
import { JwtPayload } from "jwt-decode";
import { getDecodedJwtToken } from "utils/token";
import { IS_ONCE_PH_PORTAL } from "config";

export function SubHeader() {
  const userProfile = useUserProfile();
  const jwtToken: JwtPayload = getDecodedJwtToken();
  const { t } = useT();

  return (
    <AppContentContainer
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        bgcolor: "primary.main",
        gap: "32px",
        color: "primary.contrastText",
      }}
    >
      <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
        {
          !IS_ONCE_PH_PORTAL ?
            t("WELCOME").replace(
              "{Name}",
              `${userProfile.data?.data?.results?.user?.first_name} ${userProfile.data?.data?.results?.user?.last_name}`
            )
            : t("WELCOME_WITHOUT_NAME")
        }
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <EmailIcon fontSize="small" />
          <Typography variant="subtitle1">
            {!IS_ONCE_PH_PORTAL ? (userProfile.data?.data?.results?.user?.email || "") : jwtToken.sub}
          </Typography>
        </Box>
        {userProfile.data?.data?.results?.user?.phone_number && (
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <CallIcon fontSize="small" />
            <Typography variant="subtitle1">
              {userProfile.data?.data?.results?.user?.phone_number || ""}
            </Typography>
          </Box>
        )}
      </Box>
    </AppContentContainer>
  );
}
