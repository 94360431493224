import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface LocationState {
  fromLocation?: string;
}

const initialState: LocationState = {
  fromLocation: null,
};

const locationStateSlice = createSlice({
  name: "loader",
  initialState: initialState,
  reducers: {
    setFromLocation: (state, action: PayloadAction<string>) => {
      state.fromLocation = action.payload;
    },
  },
});

export const { setFromLocation } = locationStateSlice.actions;
export default locationStateSlice.reducer;
