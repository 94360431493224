import { DOMAIN_NAME } from "config";
import { signOut } from "store/features/authSlice";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import { store } from "store/store";

type RequestOptions = {
  method: string;
  headers: Record<string, string>;
  body?: string;
  signal?: AbortSignal;
};

class APIWrapper {
  private baseURL: string;
  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  private async request<T = any>(
    endpoint: string,
    method: string = "GET",
    body: any = null,
    headers: Record<string, string> = {},
    isAuthenticated = true,
    signal: AbortSignal | null = null
  ): Promise<T> {
    let url = `${this.baseURL}/${endpoint}`;
    const access_token = getFromLocalStorage(LSKeys.ACCESS_TOKEN);
    const options: RequestOptions = {
      method,
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...(isAuthenticated ? { Authorization: `Bearer ${access_token}` } : {}),
        "X-STERE-DOMAIN": DOMAIN_NAME,
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    if (signal) {
      options.signal = signal;
    }

    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        store?.dispatch(signOut());
        window?.location?.replace("/login");
      }
      if (!response.ok) {
        const errorResponse = await response.json();
        throw errorResponse;
      }
      return await response.json();
    } catch (error) {
      console.error(`Fetch error: ${error.message}`);
      throw error;
    }
  }

  async get<T = any>(
    endpoint: string,
    headers: Record<string, string> = {},
    isAuthenticated = true,
    signal: AbortSignal | null = null
  ): Promise<T> {
    return this.request<T>(
      endpoint,
      "GET",
      null,
      headers,
      isAuthenticated,
      signal
    );
  }

  async post<T = any, U = any>(
    endpoint: string,
    body: U,
    headers: Record<string, string> = {},
    isAuthenticated = true,
    signal: AbortSignal | null = null
  ): Promise<T> {
    return this.request<T>(
      endpoint,
      "POST",
      body,
      headers,
      isAuthenticated,
      signal
    );
  }

  async put<T = any, U = any>(
    endpoint: string,
    body: U,
    headers: Record<string, string> = {},
    isAuthenticated = true,
    signal: AbortSignal | null = null
  ): Promise<T> {
    return this.request<T>(
      endpoint,
      "PUT",
      body,
      headers,
      isAuthenticated,
      signal
    );
  }

  async delete<T = any>(
    endpoint: string,
    headers: Record<string, string> = {},
    isAuthenticated = true,
    signal: AbortSignal | null = null
  ): Promise<T> {
    return this.request<T>(
      endpoint,
      "DELETE",
      null,
      headers,
      isAuthenticated,
      signal
    );
  }
}

const api = new APIWrapper(process.env.REACT_APP_SERVER_URL_V2);

export default api;
