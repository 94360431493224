export const submissionStatusMapping: {
  [key: string]: {
    label: string;
    backgroundColor: string;
    color: string;
  };
} = {
  quotes_generated: {
    label: "QUOTES_PAGE.status.quotesGenerated",
    backgroundColor: "#4CAF50",
    color: "#FFFFFF",
  },
  no_quotes_generated: {
    label: "QUOTES_PAGE.status.noQuoteGenerated",
    backgroundColor: "#D9C350",
    color: "#FFFFFF",
  },
  quotes_sent: {
    label: "QUOTES_PAGE.status.quoteSent",
    backgroundColor: "#2762EA",
    color: "#FFFFFF",
  },
  payment_made: {
    label: "QUOTES_PAGE.status.paymentMade",
    backgroundColor: "#1B5E20",
    color: "#FFFFFF",
  },
};

export const getSubmissionStatus = (metrics: MetricsInfo, product: string) => {
  if (!metrics) {
    return "";
  }
  const productMetrics = metrics.by_products.find(
    (p) => p?.product_code === product
  )?.metrics;

  if (productMetrics?.payments_recorded > 0) {
    return "payment_made";
  }
  if (productMetrics?.quotes_shortlisted > 0) {
    return "quotes_sent";
  }
  if (
    productMetrics?.quotes_generated == 0 &&
    productMetrics?.quotes_shortlisted == 0 &&
    productMetrics?.quotes_bound == 0
  ) {
    return "no_quotes_generated";
  }
  if (productMetrics?.quotes_generated > 0) {
    return "quotes_generated";
  }
  return "";
};

export const generateQuoteData = (r: Submission): QuotesData => {
  const quoteData: QuotesData = {
    submissionId: r.id,
    applicationId: r.application_id,
    applicantName: r?.application_params?.full_name,
    company_name: r?.application_params?.company_name,
    date: r?.created_at,
    insurance: r?.quotes,
    status: getSubmissionStatus(
      r?.metrics,
      r?.application_params?.products?.[0]
    ),
    metrics: r?.metrics,
  };
  return quoteData;
};

export const generateQuotesData = (data: any) => {
  let rows: QuotesData[] = [];
  data.forEach((r: any) => {
    let params_products = r.application_params?.products;
    params_products
      ?.map((str) => {
        let quotes = r.quotes.filter((obj) => obj.product === str);
        if (quotes?.length) {
          return {
            prod: quotes[0].product_details?.type,
            quotes,
          };
        }
        return null;
      })
      .filter((obj) => obj !== null);

    rows.push(generateQuoteData(r));
  });
  return rows;
};
