import { Box, Grid, Typography } from "@mui/material";
import OutletHeader from "components/Home/Common/OutletHeader";
import AnimatedButton from "components/custom/AnimatedButton";
import AgencyDetailForm from "modules/Accounts/AgencyDetailForm";
import CreateBrokerAccount from "modules/Accounts/CreateBrokerAccount";
import RequestAcceptInvite from "modules/Accounts/RequestAcceptInvite";
import useUpdateRole from "modules/Agents/useUpdateRole";
import { useState } from "react";
import { useT } from "translation";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function AgentOnBoarding({ fullName }: { fullName: string }) {
  const { t } = useT();
  const [createAgencyAsAgent, setCreateAgencyAsAgent] = useState(false);
  const { changeToAdmin, isLoading } = useUpdateRole();
  const handleAgentToAdminConversion = (callback) => {
    changeToAdmin(callback);
  }
  const close = () => setCreateAgencyAsAgent(false);
  const open = () => setCreateAgencyAsAgent(true);
  if (createAgencyAsAgent) {
    return (
      <Box>
        <AnimatedButton
          onClick={close}
          startIcon={<ArrowBackIcon />}
        >
          {t("BACK")}
        </AnimatedButton>
        <Box
          textAlign="center"
          p={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography
            variant="h6"
            gutterBottom
            style={{ fontWeight: 700, marginBottom: "16px" }}
          >
            {t("AGENCY_DETAILS")}
          </Typography>
          <AgencyDetailForm
            postCreateAccountAction={handleAgentToAdminConversion}
            isPostAccountActionInProgress={isLoading}
            postSendMembershipRequest={close}
          />
        </Box>
      </Box>
    )
  }
  return (
    <>
      <Typography variant="body1" color="primary" sx={{ fontWeight: "bold", mb: 1 }}>{t("WELCOME").replace(
        "{Name}",
        `${fullName}`
      )}!</Typography>
      <OutletHeader title={t("GET_FULL_ACCESS")} subTitle={t("TO_ACCESS_ALL_FEATURES")} />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <RequestAcceptInvite />
          </Grid>
          <Grid item xs={6}>
            <CreateBrokerAccount open={open} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AgentOnBoarding