import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import EmailIcon from "@mui/icons-material/Email";
import { useMutation } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { registerUserApi } from "modules/Auth/api";
import { IRegisterUser, IRegisterUserResponse } from "modules/Auth/types";
import { useContext, useState } from "react";
import { customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import useDashboardStartScreen from "hooks/useDashboardStartScreen";

const VerifyEmail = () => {
  const { t } = useT();
  const location = useLocation();
  const dashboardStartScreen = useDashboardStartScreen()

  const registeredUser = location.state?.registeredUser;

  const { portalAccount } = useSelector((state: RootState) => state.auth);
  const customStyles = useSelector(customStylesSelector);
  const { setToast } = useContext(ToastCtx);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const resendVerificationMailMutation = useMutation<
    IRegisterUserResponse,
    any,
    IRegisterUser
  >({
    mutationFn: registerUserApi,
    onSuccess: () => { },
    onError: ({ error }) => {
      if (error.code === "user_not_verified") {
        setIsEmailSent(true);
        return setToast({
          severity: "success",
          text: t("PLEASE_CHECK_YOUR_EMAIL"),
        });
      }
      setToast({ severity: "error", text: t("UNABLE_TO_RESEND_EMAIL") });
    },
  });

  const handleResendEmail = () => {
    if (registeredUser) {
      resendVerificationMailMutation.mutate({
        first_name: registeredUser.first_name,
        last_name: registeredUser.last_name,
        email: registeredUser.email,
        password: registeredUser.password,
      });
    }
  };

  if (portalAccount) {
    if (portalAccount?.is_temporary_password) {
      return (
        <Navigate
          to="/create-new-password"
          state={{ from: location }}
          replace
        />
      );
    } else {
      return <Navigate to={dashboardStartScreen} replace />;
    }
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor={customStyles.muiTheme.palette.background.default}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        p={3}
        maxWidth="500px"
      >
        <Box
          fontSize={"36px"}
          width={"50px"}
          height={"50px"}
          borderRadius={"50%"}
          marginBottom={"8px"}
          sx={{
            display: "flex",
            background: customStyles?.muiTheme?.palette?.primary.main,
            justifyContent: "center",
            alignItems: "center",
            padding: "4px",
          }}
        >
          <EmailIcon
            fontSize="inherit"
            color="inherit"
            sx={{ color: customStyles.button.color }}
          />
        </Box>
        <Box textAlign={"center"}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontWeight: 700,
              marginBottom: "16px",
              color: customStyles?.muiTheme?.palette?.primary.main,
            }}
          >
            {t("VERIFY_EMAIL_GET_STARTED")}
          </Typography>
          <Typography
            variant="body1"
            paragraph
            style={{ marginBottom: "24px" }}
            gutterBottom
          >
            {t("SENT_VERIFICATION_EMAIL")}{" "}
            {registeredUser?.email ? (
              <b>{registeredUser.email}</b>
            ) : (
              <>{t("YOUR_EMAIL")}</>
            )}{" "}
            {t("ENSURE_ACCOUNT_SECURITY_MESSAGE")}
          </Typography>
          <Typography
            variant="body1"
            paragraph
            style={{ marginBottom: "24px" }}
          >
            {t("CHECK_SPAM_FOLDER")}
          </Typography>
        </Box>

        {registeredUser && (
          <AnimatedButton
            variant="contained"
            color="primary"
            onClick={handleResendEmail}
            style={{ textTransform: "none" }}
            isLoading={resendVerificationMailMutation.isPending}
            disabled={isEmailSent}
          >
            {t("RESEND_EMAIL")}
          </AnimatedButton>
        )}
      </Box>
    </Box>
  );
};

export default VerifyEmail;
