import { ClientProduct } from "customTypes/ClientProducts";
import { RootState } from "store/store";

export const globalLoadingState = (state: RootState): boolean => {
  return state.loader.isLoading;
};

export const quotesSelector = (state: RootState) => {
  return state.quotes;
};

export const customStylesSelector = (state: RootState) => {
  return state.styles.customStyles;
};

export const surveyJSDataSelector = (state: RootState) => {
  return state.surveyJs;
};

export const customerSelector = (state: RootState): string => {
  return state.client.customerId;
};

export const authSelector = (state: RootState) => {
  return state.auth;
};

export const paymentSelector = (state: RootState) => {
  return state.payment;
};

export const productsSelector = (state: RootState): ClientProduct[] => {
  return state.client.startPageSelection;
};

export const startStepLoadingValueSelector = (state: RootState): boolean => {
  return state.client.loading;
};
export const applicationStepLoadingValueSelector = (
  state: RootState
): boolean => {
  return state.surveyJs.loading;
};

export const clientSelector = (state: RootState) => {
  return state.client;
};

export const customerDetailsSelector = (state: RootState) => {
  return state.customerDetails;
};

export const globalStateSelector = (state: RootState) => {
  return state.global;
};

export const locationStateSelector = (state: RootState) => {
  return state.locationState;
};
