export enum LSKeys {
  ACCESS_TOKEN = "access_token",
  // used for the Fairfax Delegated authorization flow
  REDIRECT_URI = "redirect_uri",
}
export const saveToLocalStorage = (key: LSKeys, value: any) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error("Error saving to Local Storage", error);
    throw error;
  }
};

export const getFromLocalStorage = (key: LSKeys) => {
  try {
    const serializedValue = localStorage.getItem(key);
    return serializedValue ? JSON.parse(serializedValue) : null;
  } catch (error) {
    console.error("Error retrieving from Local Storage", error);
    return null;
  }
};

export const deleteFromLocalStorage = (key: LSKeys) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error("Error deleting from Local Storage", error);
    throw error;
  }
};
