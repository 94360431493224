import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

interface QuotesState {
  availableQuotes: Quote[];
  invalidQuotes: Quote[];
  selectedQuotes: Quote[];
  selectedQuotesForSendingEmail: Quote[];
  sendingQuotes: boolean;
  sentQuotesToken: string;
  sentQuotesError: string;
}

const initialState: QuotesState = {
  availableQuotes: [],
  invalidQuotes: [],
  selectedQuotes: [],
  selectedQuotesForSendingEmail: [],
  sendingQuotes: false,
  sentQuotesToken: null,
  sentQuotesError: null,
};

const quotesSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    setAvailableQuotes: (state, action: PayloadAction<Quote[]>) => {
      state.availableQuotes = action.payload;
    },
    setInvalidQuotes: (state, action: PayloadAction<Quote[]>) => {
      state.invalidQuotes = action.payload;
    },
    addOrRemoveQuote: (state, action: PayloadAction<Quote>) => {
      if (state.selectedQuotes.find((q) => q.id === action.payload.id)) {
        state.selectedQuotes = state.selectedQuotes.filter(
          (q) => q.id !== action.payload.id
        );
      } else {
        state.selectedQuotes = state.selectedQuotes.filter(
          (q) =>
            q?.product_details?.type !== action.payload.product_details?.type
        );
        state.selectedQuotes = [...state.selectedQuotes, action.payload];
      }
    },
    removeSelectedQuote: (state, action: PayloadAction<Quote>) => {
      state.selectedQuotes = state.selectedQuotes.filter(
        (q) => action.payload.id !== q.id
      );
    },
    addOrRemoveQuoteForSendingEmail: (state, action: PayloadAction<Quote>) => {
      if (
        state.selectedQuotesForSendingEmail.find(
          (q) => q.id === action.payload.id
        )
      ) {
        state.selectedQuotesForSendingEmail =
          state.selectedQuotesForSendingEmail.filter(
            (q) => q.id !== action.payload.id
          );
      } else {
        state.selectedQuotesForSendingEmail = [
          ...state.selectedQuotesForSendingEmail,
          action.payload,
        ];
      }
    },
    sendQuotes: (state, action: PayloadAction<SendQuotesPayload>) => {
      state.sendingQuotes = true;
    },
    sendQuoteForReview: (
      state,
      action: PayloadAction<SendQuoteForReviewPayload>
    ) => {
      state.sendingQuotes = true;
    },
    setSentQuotesToken: (state, action: PayloadAction<string>) => {
      state.sentQuotesToken = action.payload;
    },
    setSentQuotesError: (state, action: PayloadAction<string>) => {
      state.sentQuotesError = action.payload;
    },
    setSendingQuotes: (state, action: PayloadAction<boolean>) => {
      state.sendingQuotes = action.payload;
    },
    resetQuotes: () => initialState,
    resetSelectedQuotes: (state) => {
      state.selectedQuotes = [];
    },
    resetQuotesForSendingEmail: (state) => {
      state.selectedQuotesForSendingEmail = [];
    },
  },
});

export const {
  setAvailableQuotes,
  setInvalidQuotes,
  addOrRemoveQuote,
  addOrRemoveQuoteForSendingEmail,
  resetQuotesForSendingEmail,
  sendQuotes,
  sendQuoteForReview,
  setSendingQuotes,
  setSentQuotesToken,
  setSentQuotesError,
  resetQuotes,
  resetSelectedQuotes,
  removeSelectedQuote,
} = quotesSlice.actions;

export default quotesSlice.reducer;
