import { Box, CircularProgress, } from "@mui/material";
import { useQuery } from '@tanstack/react-query'
import { fetchUserProfileDetails } from "modules/Profile/api";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import { IUserDetails } from "modules/Profile/types";
import ErrorComponent from "components/common/ErrorComponent/ErrorComponent";
import { useMemo } from "react";
import AdminOnBoarding from "modules/Accounts/AdminOnBoarding";
import AgentOnBoarding from "modules/Accounts/AgentOnBoarding";
import { useT } from "translation";
import { ERole } from "modules/Accounts/types";

function OnBoarding() {
  const accessToken = getFromLocalStorage(LSKeys.ACCESS_TOKEN);
  const { t } = useT()
  const {
    data: userDetails,
    isLoading: isUserDetailsLoading,
    isError: isUserDetailsError,
  } = useQuery<IUserDetails>({
    queryFn: fetchUserProfileDetails.queryFn,
    queryKey: fetchUserProfileDetails.queryKey,
    enabled: Boolean(accessToken),
  });

  const isAdmin = useMemo(() => {
    return userDetails?.meta?.roles?.includes(ERole.ADMIN);
  }, [userDetails?.meta?.roles])

  if (isUserDetailsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Box>
    )
  }

  if (isUserDetailsError) {
    return <ErrorComponent />
  }

  if (isAdmin) {
    return <AdminOnBoarding />
  }

  return (
    <AgentOnBoarding fullName={`${userDetails.first_name} ${userDetails.last_name}`} />
  )
}

export default OnBoarding;