export const loginPageDataAttr = {
  LOGIN_EMAIL: "LOGIN_EMAIL",
  LOGIN_PASSWORD: "LOGIN_PASSWORD",
  LOGIN_BUTTON: "LOGIN_BUTTON",
  GO_TO_REGISTER_PAGE: "GO_TO_REGISTER_PAGE",
};

export const dashboardPageDataAttr = {
  AGENTS_TAB: "AGENTS_TAB",
};

export const agentsPageDataAttr = {
  MY_AGENTS_TAB: "MY_AGENTS_TAB",
  INVITE_AGENTS_TAB: "INVITE_AGENTS_TAB",

  AGENTS_TABLE_ROW: "AGENTS_TABLE_ROW",
  INVITE_AGENTS_TABLE_ROW: "INVITE_AGENTS_TABLE_ROW",

  AGENT_INVITE_BTN: "INVITE_BTN",
  AGENT_INVITE_EMAIL_INPUT: "AGENT_INVITE_EMAIL_INPUT",
  AGENT_SEND_INVITE_BTN: "AGENT_SEND_INVITE_BTN",

  AGENT_INVITE_DELETE_BTN: "AGENT_INVITE_DELETE_BTN",
};

export const settingsPage = {
  AGENCY_NAME_FIELD: "AGENCY_NAME_FIELD",
  AGENCY_TAX_ID_FIELD: "AGENCY_TAX_ID_FIELD",
  AGENCY_ADDRESS_FIELD: "AGENCY_ADDRESS_FIELD",
  AGENCY_LICENSE_FIELD: "AGENCY_LICENSE_FIELD",
  AGENCY_DETAILS_UPDATE_BTN: "AGENCY_DETAILS_UPDATE_BTN",

  USER_SETTINGS_TAB: "USER_SETTINGS_TAB",
  AGENCY_SETTINGS_TAB: "AGENCY_SETTINGS_TAB",

  USER_FIRST_NAME_FIELD: "USER_FIRST_NAME_FIELD",
  USER_LAST_NAME_FIELD: "USER_LAST_NAME_FIELD",
  USER_EMAIL_FIELD: "USER_EMAIL_FIELD",
  UPDATE_USER_DETAILS_BTN: "UPDATE_USER_DETAILS_BTN",
};
