import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  createPurchase,
  createSubmissionsIdAPI,
  generateQuotesAPI,
  updateSiaApplicationAPI,
} from "api/siaApplication";
import CircularProgressLoader from "components/loader/Loader";
import {
  COUNTRY,
  CountryMapping,
  FAIRFAX,
  IS_BROKER_PORTAL,
  IS_HOSTED_INSURANCE,
  PORTAL_NAME,
} from "config";
import { stepperSteps } from "constants/stepperSteps";
import {
  STERE_APPLICATION_COMPLETED,
  STERE_APPLICATION_IN_PROGRESS,
  STERE_QUOTES_GENERATED,
  STERE_QUOTES_MISSING,
} from "constants/webhookEvents";
import { checkAndCreateProductsArray } from "saga/surveyJSSaga";
import { setErrorAndShowExitBtn } from "store/features/clientSlice";
import { setLoaderText } from "store/features/loaderSlice";
import { setPurchase } from "store/features/paymentSlice";
import {
  resetSelectedQuotes,
  setAvailableQuotes,
  setInvalidQuotes,
} from "store/features/quotesSlice";
import {
  nextStep,
  nextSubStep,
  prevStep,
  prevSubStep,
  setCurrentSubStep,
  setShowPaymentScreen,
} from "store/features/stepperSlice";
import {
  sendClientSIALatestConfig,
  setLoadingValue,
  setSubmissionid,
  setSurveyPageNumber,
  setUpdatedSIAApplication,
  updateSIAApplication,
} from "store/features/surveyJsSlice";
import { fireWebhookEvent } from "store/features/webhooksSlice";
import { globalLoadingState, quotesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import {
  PaymentProviders,
  getPrefillData,
  getTimestamp,
  sendMessageToClient,
} from "utils/utils";
import CustomButton from "../CustomButton/CustomButton";

const FooterNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useT();

  const isMobile = useMediaQuery("(max-width:480px)");
  const scriptLoaded = localStorage.getItem("scriptLoaded");

  // fetching values from redux store
  const loadingState = useSelector(globalLoadingState);
  const { currentStep, currentSubStep, showPaymentScreen } = useSelector(
    (state: RootState) => state.stepper
  );
  const {
    surveyModelFuncs,
    siaApplicationId,
    clientLatestConfig,
    submissionId,
    knockout,
  } = useSelector((state: RootState) => state.surveyJs);
  const quotes = useSelector(
    (state: RootState) => state.quotes.availableQuotes
  );
  const { showPaymentSummaryPage, showAutoRenewalSwitch } = useSelector(
    (state: RootState) => state.client
  );
  const [isBindingQuote, setIsBindingQuote] = useState(false);
  const {
    clientAPIKey,
    productSelection,
    availableProducts,
    startPageSelection,
    clientUrl,
    errorAndShowExitBtn,
    clientFeatures,
    applicantId,
  } = useSelector((state: RootState) => {
    return state.client;
  });
  const { purchase } = useSelector((state: RootState) => state.payment);
  const { selectedQuotes, sentQuotesToken, sentQuotesError } =
    useSelector(quotesSelector);
  const maxSteps = useSelector((state: RootState) => state.stepper.maxSteps);
  const [selectedQuote] = selectedQuotes;
  const productIdentifier = useSelector(
    (state: RootState) =>
      state.client.availableProducts[0]?.product?.product_identifier
  );

  const IS_SILVERSKY = COUNTRY === "sg" && productIdentifier === "PET";
  const [docusealTemplateSlug, setDocusealTemplateSlug] = useState(
    sessionStorage.getItem("template_slug")
  );

  const subSteps = surveyModelFuncs?.getPageTitles().map((s) => ({ label: s }));
  //

  const saveApplicationAndGenerateQuotes = async () => {
    try {
      dispatch(setLoaderText(t("SUBMITTING_RESPONSE")));
      dispatch(setLoadingValue(true));
      dispatch(resetSelectedQuotes());
      let products = checkAndCreateProductsArray(
        productSelection,
        startPageSelection,
        availableProducts
      );
      const surveyData = getPrefillData();
      // Remove the keys with spacing
      for (const key in surveyData) {
        key.trim();
        if (key.includes(" ")) {
          delete surveyData[key];
        }
      }
      delete surveyData.knockout;
      let payload = {
        country: CountryMapping[COUNTRY],
        products: products,
        params: {
          ...surveyData,
          products,
          ...(productIdentifier === "PET" && selectedQuote && knockout
            ? { ...(selectedQuote.premium?.additional_info?.plan_info ?? {}) }
            : {}),
        },
      };
      // updating the application and awaiting form status
      let res = await updateSiaApplicationAPI(
        clientAPIKey,
        siaApplicationId,
        payload
      );
      dispatch(setUpdatedSIAApplication(res.data));
      if (res.data.status === "complete") {
        //awaiting creation of submissions id
        if (!IS_BROKER_PORTAL) {
          dispatch(
            fireWebhookEvent({
              event_type: STERE_APPLICATION_COMPLETED,
              metadata: {
                applicantId: applicantId,
                applicationId: res.data.id,
                applicationData: res.data.params,
                timeStamp: getTimestamp(),
              },
            })
          );
        }
        const submsID =
          IS_SILVERSKY && selectedQuote
            ? selectedQuote?.submission_id
            : submissionId;

        let newSubmissionId = "";
        // Note: For MSIG we need to avoid creating new submission
        if (!submsID) {
          const subRes = await createSubmissionsIdAPI(clientAPIKey, {
            application_id: siaApplicationId,
          });
          newSubmissionId = subRes?.data?.id;
        }

        if (submsID || newSubmissionId) {
          //awaiting generation of quotes
          dispatch(setLoaderText(t("FETCHING_PLANS")));
          if (newSubmissionId) {
            dispatch(setSubmissionid(newSubmissionId));
          }

          const quotesRes = submsID?.length
            ? await generateQuotesAPI(clientAPIKey, submsID, true)
            : await generateQuotesAPI(clientAPIKey, newSubmissionId, false);

          newSubmissionId = submsID;
          if (
            quotesRes?.data?.status === "generated_quotes" &&
            quotesRes?.data?.quotes?.length
          ) {
            // array of only valid(quotes with status - generated) quotes.
            const filteredQuotes = (quotesRes?.data?.quotes || []).filter(
              (q: Quote) => {
                // Note: For MSIG we need to show only selected quote at start
                if (IS_SILVERSKY && selectedQuote) {
                  return (
                    q.premium?.additional_info?.plan_name?.default ===
                      selectedQuote?.premium?.additional_info?.plan_name
                        ?.default &&
                    q.premium?.term === selectedQuote?.premium?.term &&
                    q.status === "generated"
                  );
                }
                return q.status === "generated";
              }
            );

            const invalidQuotes = (quotesRes?.data?.quotes || []).filter(
              (q) => q.status === "invalid" || q.status === "referred"
            );
            const quotesMetaData = filteredQuotes?.map((q) => {
              return {
                quote_id: q.id,
                premium: {
                  currency: q.premium?.currency,
                  premium_value: q.premium?.premium_value,
                  tax: q.premium?.tax,
                },
                insurer_name: q?.product_details?.insurer,
              };
            });
            dispatch(setAvailableQuotes(filteredQuotes));
            dispatch(setInvalidQuotes(invalidQuotes));
            if (!IS_BROKER_PORTAL) {
              dispatch(
                fireWebhookEvent({
                  event_type: STERE_APPLICATION_IN_PROGRESS,
                  metadata: {
                    applicantId: applicantId,
                    applicationId: siaApplicationId,
                    currentStep: subSteps?.[subSteps?.length - 1].label,
                    currentStepStatus: "completed",
                    remainingSteps: [],
                  },
                })
              );
            }
            // emit the STERE_QUOTES_GENERATED event if the response returned atleast 1 valid quote
            if (!IS_BROKER_PORTAL && filteredQuotes?.length) {
              dispatch(
                fireWebhookEvent({
                  event_type: STERE_QUOTES_GENERATED,
                  metadata: {
                    applicantId: applicantId,
                    applicationId: res.data.id,
                    submissionid: newSubmissionId,
                    quotes: quotesMetaData,
                  },
                })
              );
            }
            // emit the STERE_QUOTES_MISSING event if the quotes array has some quotes, but all those quotes are invalid
            else if (!IS_BROKER_PORTAL && !filteredQuotes?.length) {
              dispatch(
                fireWebhookEvent({
                  event_type: STERE_QUOTES_MISSING,
                  metadata: {
                    applicantId: applicantId,
                    applicationId: res.data.id,
                    submissionid: newSubmissionId,
                  },
                })
              );
            }
          }
          // emit the STERE_QUOTES_MISSING event if the quotes array is empty.
          else if (!IS_BROKER_PORTAL) {
            dispatch(
              fireWebhookEvent({
                event_type: STERE_QUOTES_MISSING,
                metadata: {
                  applicantId: applicantId,
                  applicationId: res.data.id,
                  submissionid: newSubmissionId,
                },
              })
            );
          }
        }

        // updating the client's current progress
        dispatch(
          sendClientSIALatestConfig({
            mainStep: "2",
            submissionsId: newSubmissionId,
            touched: true,
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(nextStep());
      dispatch(setLoaderText(""));
      dispatch(setCurrentSubStep(0));
      setTimeout(() => {
        dispatch(setLoadingValue(false));
      }, 100);
    }
  };

  const insideSurvey = location?.pathname?.split("/")[2] === "application";
  const isPaymentScreen = location?.pathname?.split("/")[2] === "payment";
  const isCustomerDetailsScreen =
    location?.pathname?.split("/")[2] === "customerDetails";
  const isPrevStepSurvey = location?.pathname?.split("/")[2] === "quote";
  const isNextStepSurvey = location?.pathname?.split("/")[2] === "start";

  const handleSurveyBack = () => {
    if (
      surveyModelFuncs?.isFirstPage() ||
      surveyModelFuncs?.isFirstPage() === void 0
    ) {
      if (knockout) {
        navigate("/knockout");
      } else {
        dispatch(prevStep());
      }
    } else {
      surveyModelFuncs?.prevPageFunc();
      dispatch(prevSubStep());
      dispatch(setSurveyPageNumber(surveyModelFuncs?.currentPageNo()));
    }
  };

  const handleSurveyNext = () => {
    if (surveyModelFuncs?.isLastPage()) {
      if (surveyModelFuncs?.checkValidState()) {
        saveApplicationAndGenerateQuotes();
        dispatch(setSurveyPageNumber(surveyModelFuncs?.currentPageNo()));
      }
    } else {
      if (surveyModelFuncs?.checkValidState()) {
        surveyModelFuncs.nextPageFunc();
        if (!IS_BROKER_PORTAL) {
          dispatch(
            fireWebhookEvent({
              event_type: STERE_APPLICATION_IN_PROGRESS,
              metadata: {
                applicantId: applicantId,
                applicationId: siaApplicationId,
                currentStep: subSteps?.[currentSubStep].label,
                currentStepStatus: "completed",
                remainingSteps: subSteps
                  ?.slice(currentSubStep + 1)
                  ?.map((s) => s?.label),
              },
            })
          );
        }
        dispatch(nextSubStep());
        dispatch(setSurveyPageNumber(surveyModelFuncs?.currentPageNo()));
        dispatch(updateSIAApplication());

        if (!clientLatestConfig?.touched) {
          dispatch(
            sendClientSIALatestConfig({
              mainStep: "1",
              subStep: surveyModelFuncs?.currentPageNo(),
              touched: true,
            })
          );
        }
      }
    }
  };

  const onBackClick = () => {
    if (isPrevStepSurvey) {
      dispatch(prevStep());
      dispatch(setCurrentSubStep(surveyModelFuncs?.currentPageNo()));
    } else if (insideSurvey) {
      handleSurveyBack();
    } else {
      if (IS_SILVERSKY) {
        dispatch(setPurchase(null));
      }
      dispatch(prevStep());
    }
  };

  const onNextClick = () => {
    if (isNextStepSurvey) {
      dispatch(nextStep());
    } else if (insideSurvey) {
      handleSurveyNext();
    } else if (currentStep < maxSteps - 1) {
      dispatch(nextStep());
    }
  };

  const onMakePayment = async () => {
    // Checking for autopylot explicitly
    if (
      COUNTRY === "us" &&
      productIdentifier === "DRONE" &&
      selectedQuote &&
      showAutoRenewalSwitch
    ) {
      try {
        // Not calling create purchase API if purchase already exists for the selected quote. If the quote changes
        // then we will create a new purchase object.
        if (
          purchase &&
          purchase.id &&
          purchase.for_ids.includes(selectedQuote.id)
        ) {
          dispatch(setShowPaymentScreen(true));
          return;
        } else {
          // Calling the createPurchase API with the auto renew consent as true
          const res = await createPurchase(clientAPIKey, {
            for_type: "quote",
            for_ids: [selectedQuote.id],
            auto_renewal_consent: true,
          });
          dispatch(setPurchase(res.data));
          dispatch(setShowPaymentScreen(true));
        }
      } catch (error) {
        dispatch(setErrorAndShowExitBtn(true));
      } finally {
      }
    } else {
      dispatch(setShowPaymentScreen(true));
    }
  };

  const enableDisableBtn = () => {
    if (currentStep === 0 && !startPageSelection?.length) {
      return true;
    } else if (
      (IS_BROKER_PORTAL ? currentStep === 3 : currentStep === 2) &&
      !selectedQuotes?.length
    )
      return true;
    else if (currentStep === 3) return true;
    return false;
  };

  useEffect(() => {
    // Update state when sessionStorage changes
    const handleStorageChange = () => {
      setDocusealTemplateSlug(sessionStorage.getItem("template_slug"));
    };

    // Listen to storage changes
    window.addEventListener("storage", handleStorageChange);

    return () => {
      // Clean up listener
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    let path = "/newQuote/";
    const currentLabel = stepperSteps()[currentStep]?.label;
    const newPath =
      currentLabel?.substring(0, 1).toLowerCase() + currentLabel?.substring(1);
    if (newPath && newPath.length) {
      path += newPath;
      if (path !== location.pathname) {
        navigate(path);
      }
    }
  }, [currentStep, location.pathname, navigate]);

  const whenToShowNextBtn = () => {
    if (IS_BROKER_PORTAL) {
      // maxSteps -> 3 currentStep -> (0,1,2)
      return currentStep < maxSteps - 1;
    } else if (IS_HOSTED_INSURANCE) {
      if (errorAndShowExitBtn) return false;
      // maxSteps -> 5 currentStep -> (0,1,2,3,4)
      return currentStep < maxSteps - 2;
    }
  };

  const whenToShowBackBtn = () => {
    if (IS_BROKER_PORTAL) {
      if (
        PORTAL_NAME === FAIRFAX &&
        currentStep === 1 &&
        currentSubStep === 0
      ) {
        return false;
      }
      // currentStep shouldn't be the first step and less than maxSteps
      if (currentStep > 0 && currentStep < maxSteps) {
        // if the quotes were sent successfully or errored out, don't show back btn. Else show
        if (sentQuotesToken || sentQuotesError) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    } else if (IS_HOSTED_INSURANCE) {
      // minStep depends on the product selection made by the client
      const minStep = productSelection?.length ? 1 : 0;
      if (errorAndShowExitBtn) {
        return false;
      } else if (
        currentStep === maxSteps - 1 ||
        (clientFeatures?.payment_provider !== PaymentProviders.ANDDONE &&
          currentStep === maxSteps - 2 &&
          showPaymentScreen)
      ) {
        return false;
      } else if (
        currentStep > minStep ||
        (currentStep === 1 && currentSubStep > 0)
      ) {
        return true;
      } else if (knockout && minStep === currentStep) {
        return true;
      }
      return false;
    }
  };

  const whenToShowExitBtn = () => {
    if (IS_BROKER_PORTAL || docusealTemplateSlug) {
      return false;
    } else if (IS_HOSTED_INSURANCE) {
      if (errorAndShowExitBtn) {
        return true;
      }
      // show Exit btn if no quotes were generated
      else if (currentStep === 2 && !quotes?.length) {
        return true;
      }
      // show Exit btn at the last step
      else if (currentStep === maxSteps - 1) {
        return true;
      }
      return false;
    }
  };

  const whenToShowMakePaymentBtn = () => {
    if (IS_BROKER_PORTAL) {
      return false;
    } else if (IS_HOSTED_INSURANCE) {
      if (errorAndShowExitBtn) return false;
      if (currentStep === maxSteps - 2) {
        if (showPaymentSummaryPage) {
          return !showPaymentScreen;
        }
      }
      return false;
    }
  };

  const RenderMakePaymentButton = () => {
    if (IS_SILVERSKY && selectedQuote) {
      if (purchase?.payment_gateway?.payment_url) {
        return (
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href={purchase?.payment_gateway?.payment_url}
            target="_parent"
          >
            <CustomButton
              data_cy="make-payment-button"
              btnText={t("PAYMENT_PAGE_MAKE_PAYMENT")}
            />
          </a>
        );
      }
    } else {
      return (
        <CustomButton
          data_cy="make-payment-button"
          onClickCB={onMakePayment}
          btnText={t("PAYMENT_PAGE_MAKE_PAYMENT")}
        />
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display:
            (isPaymentScreen &&
              !whenToShowMakePaymentBtn() &&
              clientFeatures?.payment_provider === PaymentProviders.STRIPE) ||
            (IS_BROKER_PORTAL && isCustomerDetailsScreen) ||
            docusealTemplateSlug
              ? "none"
              : "flex",
          justifyContent: "flex-end",
          p: { xs: "20px", sm: "40px", md: "20px", lg: "20px" },
          boxShadow: isMobile && `0px 5px 5px -5px grey inset`,
        }}
      >
        {whenToShowBackBtn() && (
          <CustomButton
            disabled={loadingState || (insideSurvey && !scriptLoaded)}
            onClickCB={onBackClick}
            btnText={t("BACK")}
          />
        )}
        {whenToShowNextBtn() && (
          <CustomButton
            data_cy="next-button"
            disabled={
              loadingState ||
              enableDisableBtn() ||
              (insideSurvey && !scriptLoaded)
            }
            onClickCB={onNextClick}
            btnText={t(currentStep === maxSteps - 1 ? "COMPLETE" : "CONTINUE")}
          />
        )}
        {whenToShowExitBtn() && (
          <CustomButton
            onClickCB={() => {
              {
                if (errorAndShowExitBtn) {
                  sendMessageToClient(
                    { type: "EXIT_ON_FAILURE", details: {} },
                    clientUrl
                  );
                } else {
                  sendMessageToClient({ type: "EXIT", details: {} }, clientUrl);
                }
              }
            }}
            btnText={t("EXIT")}
          />
        )}
        {whenToShowMakePaymentBtn() && <RenderMakePaymentButton />}
      </Box>
      {isBindingQuote && (
        <Box
          sx={{
            position: "fixed",
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
          }}
        >
          <CircularProgressLoader />
        </Box>
      )}
    </>
  );
};

export default FooterNavigation;
