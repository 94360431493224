import { AxiosResponse } from "axios";
import { IS_BROKER_PORTAL } from "config";

import {
  createAxiosInstanceWithAccessToken,
  createAxiosInstanceWithApiKey,
} from "utils/utils";

const BASE_URL = process.env.REACT_APP_SERVER_URL + "/v1";

export async function sendQuotesAPI(
  payload: SendQuotesPayload
): Promise<AxiosResponse<AxiosCommonResponse<SendQuotesResponse>>> {
  const QuoteAPI = createAxiosInstanceWithAccessToken();
  return await QuoteAPI.post(
    `${BASE_URL}/portal_broker:send_payment_token`,
    payload
  );
}

export async function sendQuoteForReviewAPI(
  payload: SendQuoteForReviewPayload
): Promise<AxiosResponse<AxiosCommonResponse<SendQuotesResponse>>> {
  const QuoteAPI = createAxiosInstanceWithAccessToken();
  return await QuoteAPI.post(
    `${BASE_URL}/portal_broker:send_quote_for_review`,
    payload
  );
}

export async function getPaymentTokenAPI(payload: {
  submissionID: string;
}): Promise<AxiosResponse<AxiosCommonResponse<{ token: string }>>> {
  const QuoteAPI = createAxiosInstanceWithAccessToken();
  return await QuoteAPI.post(
    `${BASE_URL}/portal_broker:get_token_submission`,
    payload
  );
}

export async function makeStripePayment(payload: {
  paymentMethodId: string;
  pasApplicationId: string;
  quote: Quote;
  policyholder: Policyholder;
  companyName: string;
  userId?: string;
  clientAPIKey: string;
}): Promise<AxiosResponse<AxiosCommonResponse<StripePaymentResponse>>> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(payload.clientAPIKey);

  return await QuoteAPI.post(`/portal_broker/stripe:direct_payment`, {
    payment_method_id: payload.paymentMethodId,
    pas_application_id: payload.pasApplicationId,
    quote: payload.quote,
    user_id: payload.userId,
    company_name: payload.companyName,
    policyholder: payload.policyholder,
  });
}

export const getPaymentStatus = async (
  submissionId: string
): Promise<
  AxiosResponse<AxiosCommonResponse<{ submission_status: string }>>
> => {
  const QuoteAPI = createAxiosInstanceWithAccessToken();
  return await QuoteAPI.get(`/submissions/${submissionId}`);
};
