import { Chip } from "@mui/material";
import moment from "moment";
import { useT } from "translation";


function ValidityChip({
  startDate,
  endDate,
  status
}: {
  startDate: moment.Moment;
  endDate: moment.Moment;
  status: "pending" | "approved" | "rejected"
}) {
  const now = moment();
  const { t } = useT();

  if (now.isBefore(startDate) && status === "approved") {
    return (
      <Chip variant="outlined" sx={{ p: "0px" }} color='primary' label={t('UPCOMING')} />
    );
  } else if (now.isBetween(startDate, endDate, undefined, '[]') && status === "approved") {
    return (
      <Chip variant="outlined" sx={{ p: "0px" }} color='success' label={t('ACTIVE')} />
    );
  } else if (now.isAfter(endDate)) {
    return <Chip variant="outlined" sx={{ p: "0px" }} color="error" label={t('OUTDATED')} />;
  }
}

export default ValidityChip;
