import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import React from "react";

import TableComponent from "components/common/TableComponent";
import ViewOption from "components/common/ViewOption";
import { useNavigate } from "react-router-dom";
import { useT } from "translation";
import { capitalizeFirstLetter } from "utils/utils";
import { statusMapping } from "./utils";
import { FAIRFAX, PORTAL_NAME } from "config";
import { productTypeByPortal } from "../Common/utils";

interface ApplicationsTableProps {
  applicationDataList: ApplicationData[];
  loadingData: boolean;
  hasError: boolean;
}

const ApplicationsTable: React.FC<ApplicationsTableProps> = ({
  applicationDataList,
  loadingData,
  hasError,
}) => {
  const { t } = useT();
  const navigate = useNavigate();

  const headCells: TableComponentHeadCell[] = [
    {
      id: "applicantEmail",
      label: t("APPLICATIONS_PAGE.tableHeaders.applicant_email"),
    },
    ...(productTypeByPortal[PORTAL_NAME] === "business"
      ? [
          {
            id: "businessName",
            label: t("APPLICATIONS_PAGE.tableHeaders.business_name"),
          },
        ]
      : [
          {
            id: "applicantName",
            label: t("APPLICATIONS_PAGE.tableHeaders.applicant_name"),
          },
        ]),
    {
      id: "date",
      label: t("APPLICATIONS_PAGE.tableHeaders.date"),
    },
    {
      id: "insurance",
      label: t("APPLICATIONS_PAGE.tableHeaders.insurance"),
    },
    {
      id: "status",
      label: t("APPLICATIONS_PAGE.tableHeaders.status"),
    },
    {
      id: "action",
      label: t("APPLICATIONS_PAGE.tableHeaders.action"),
    },
  ];

  const listoptions = (application: ApplicationData) => [
    {
      title: "View",
      label: <ViewOption />,
      cb: () => {
        localStorage.removeItem("prefillData");
        const isFairFax = PORTAL_NAME === FAIRFAX;
        const clientData = {
          prefill_values: isFairFax
            ? { business_email: application?.businessEmail }
            : { applicant_email: application?.applicantEmail },
          read_only_values: isFairFax
            ? { business_email: true }
            : {
                applicant_email: true,
              },
        };
        localStorage.setItem("clientData", JSON.stringify(clientData));
        navigate(`/newQuote?applicationId=${application?.applicationId}`);
      },
    },
  ];

  const ApplicationStatus: React.FC<{ applicationData: ApplicationData }> = ({
    applicationData,
  }) => {
    const { t } = useT();
    return (
      <Box
        sx={{
          backgroundColor:
            statusMapping[applicationData.status || ""]?.backgroundColor,
          p: "5px",
          borderRadius: "100px",
          maxWidth: "110px",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: statusMapping[applicationData.status || ""]?.color,
            whiteSpace: "pre-wrap",
            textAlign: "center",
          }}
        >
          {t(statusMapping[applicationData.status || ""]?.label)}
        </Typography>
      </Box>
    );
  };

  const handleEmptyValues = (value: string) => {
    return value ? value : "-";
  };

  const tableRows: TableComponentRow[] = applicationDataList?.map(
    (application) => ({
      id: application.applicationId,
      cells: [
        {
          ...(productTypeByPortal[PORTAL_NAME] === "business"
            ? {
                id: application.businessEmail,
                label: handleEmptyValues(application?.businessEmail),
              }
            : {
                id: application.applicantEmail,
                label: handleEmptyValues(application?.applicantEmail),
              }),
        },
        {
          ...(productTypeByPortal[PORTAL_NAME] === "business"
            ? {
                id: application.company_name,
                label: handleEmptyValues(application?.company_name),
              }
            : {
                id: application.applicantName,
                label: handleEmptyValues(application?.applicantName),
              }),
        },
        {
          id: application.date,
          label: moment(application.date).format("DD MMM YYYY"),
        },
        {
          id: `${application.applicationId}-insurance`,
          label: (
            <Box>
              {
                <Typography sx={{ fontSize: "14px" }}>
                  {capitalizeFirstLetter(
                    application?.insurance[0]?.split("-")[0]
                  )}
                </Typography>
              }
            </Box>
          ),
        },
        {
          id: `${application.applicationId}-status`,
          label: <ApplicationStatus applicationData={application} />,
        },
        {
          id: `${application.applicationId}-actions`,
          actions:
            application.status === "incomplete" ? listoptions(application) : [],
          label: "Actions",
          type: "action",
        },
      ],
    })
  );

  return (
    <TableComponent
      headCells={headCells}
      loading={loadingData}
      errorMessage={hasError && t("SOMETHING_WENT_WRONG")}
      noDataMessage={t("NO_DATA_AVAILABLE")}
      rows={tableRows}
    />
  );
};
export default ApplicationsTable;
