import { DOMAIN_NAME } from "config";
import {
  IFairfaxUserLogin,
  IFairfaxUserLoginResponse,
  IRegisterUser,
  IRegisterUserResponse,
  IRequestResetPassword,
  IResetPassword,
  IUserLogin,
  IUserLoginResponse,
  IVerifyUser,
} from "modules/Auth/types";
import api from "utils/api.utils";
import { getFromLocalStorage, LSKeys } from "utils/ls.utils";

export const registerUserApi = async (
  data: IRegisterUser
): Promise<IRegisterUserResponse> => {
  try {
    const response = await api.post("users/register", data, {}, false);

    return { id: response.id, ...data };
  } catch (err) {
    throw err;
  }
};
export const verifyUserRegistration = async (
  decodedTokenData: IVerifyUser
): Promise<unknown> => {
  const response = await api.post(
    "users/verify-registration",
    decodedTokenData,
    {},
    false
  );
  return response;
};

export const loginUserApi = async (
  data: IUserLogin
): Promise<IUserLoginResponse> => {
  try {
    const response = await api.post("users/login", data, {}, false);
    return response;
  } catch (err) {
    throw err;
  }
};

export const loginUserWithFairfaxTokenApi = async (
  data: IFairfaxUserLogin
): Promise<IFairfaxUserLoginResponse> => {
  try {
    const response = await api.post("users/login-with-ffid", data, {}, false);
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchAccountsOfUserApi = async (): Promise<any> => {
  try {
    const response = await api.get("accounts");
    return response;
  } catch (err) {
    throw err;
  }
};

export const requestResetPasswordApi = async (
  data: IRequestResetPassword
): Promise<any> => {
  try {
    const response = await api.post("users/request-reset-password", data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const resetPasswordApi = async (data: IResetPassword): Promise<any> => {
  try {
    const response = await api.post("users/reset-password", data);
    return response;
  } catch (err) {
    throw err;
  }
};
