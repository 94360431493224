import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DistributionAccesses } from "modules/Appointments/types";

interface AppointmentState {
  availableDistributionAccess: DistributionAccesses[];
}

const initialState: AppointmentState = {
  availableDistributionAccess: [],
};

const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    setAvailableDistributions: (state, action: PayloadAction<DistributionAccesses[]>) => {
      state.availableDistributionAccess = action.payload;
    }
  },
});

export const {
  setAvailableDistributions,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
