import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import useDashboardStartScreen from "hooks/useDashboardStartScreen";
import { acceptMemberInvite } from "modules/Agents/api";
import { IAcceptInvite } from "modules/Agents/types";
import { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useT } from "translation";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";

const AcceptMemberInvite = () => {
  const { t } = useT();
  const dashboardStartScreen = useDashboardStartScreen();

  const { memberId } = useParams<{ memberId: string }>();
  const { setToast } = useContext(ToastCtx);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const acceptMemberInviteMutation = useMutation<
    unknown,
    unknown,
    IAcceptInvite
  >({
    mutationFn: acceptMemberInvite,
    onSuccess: () => {
      navigate(dashboardStartScreen);
      setToast({
        severity: "success",
        text: t("INVITE_ACCEPTED"),
      });
      queryClient.invalidateQueries({
        queryKey: ["accounts", getFromLocalStorage(LSKeys.ACCESS_TOKEN)],
      });
    },
    onError: ({ error }) => {
      const errorCode = error?.code || "";
      if (errorCode === "invalid_auth_key") {
        setToast({
          severity: "error",
          text: t("REGISTER_OR_LOGIN_BEFORE_ACCEPTING_INVITE"),
        });
        navigate("/register");
        return;
      }
      if (errorCode === "invite_already_accepted") {
        setToast({
          severity: "error",
          text: t("INVITE_ALREADY_ACCEPTED"),
        });
        navigate(dashboardStartScreen);
        return;
      }
      if (errorCode === "insufficient_permissions") {
        setToast({
          severity: "error",
          text: t("NO_SUFFICIENT_PERMISSION"),
        });
        navigate(dashboardStartScreen);
        return;
      }
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  useEffect(() => {
    if (!memberId) {
      return;
    }

    if (acceptMemberInviteMutation.status !== "idle") {
      return;
    }
    try {
      acceptMemberInviteMutation.mutate({ member_id: memberId });
    } catch {
      setToast({
        text: t("SOMETHING_WENT_WRONG"),
        severity: "error",
      });
    }
  }, [acceptMemberInviteMutation, memberId, setToast, t]);

  const renderView = () => {
    if (acceptMemberInviteMutation.isError) {
      return (
        <>
          {" "}
          <CancelIcon color="error" fontSize="large" />
          <Typography sx={{ fontSize: "24px", textAlign: "center" }}>
            {t("SOMETHING_WENT_WRONG")}
          </Typography>
          <AnimatedButton
            variant="outlined"
            component={Link}
            href={dashboardStartScreen}
          >
            {t("DASHBOARD")}
          </AnimatedButton>
        </>
      );
    } else if (acceptMemberInviteMutation.isSuccess) {
      return (
        <>
          {" "}
          <CheckCircleIcon color="success" fontSize="large" />
          <Typography sx={{ fontSize: "24px", textAlign: "center" }}>
            {t("INVITE_ACCEPTED")}
          </Typography>
          <AnimatedButton
            variant="outlined"
            component={Link}
            href={dashboardStartScreen}
          >
            {t("DASHBOARD")}
          </AnimatedButton>
        </>
      );
    }
    return (
      <>
        {" "}
        <CircularProgress />
        <Typography sx={{ fontSize: "24px" }}>
          {t("ACCEPTING_INVITE")}
        </Typography>
      </>
    );
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {renderView()}
    </Box>
  );
};

export default AcceptMemberInvite;
