import { Paper, Typography } from "@mui/material"
import AnimatedButton from "components/custom/AnimatedButton"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useT } from "translation";

function CreateBrokerAccount({ open }: { open: () => void }) {
  const { t } = useT();
  return (
    <Paper sx={{ p: 2, height: "100%" }}>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 3 }}>{t("CREATE_BROKER_ACCOUNT")}</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>{t("BECOME_ADMIN_AND_BUILD_TEAM")}</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>{t("NAVIGATE_TO_PROFILE")}</Typography>
      <AnimatedButton
        endIcon={<ArrowForwardIcon />}
        onClick={open}
      >
        {t("ADD_AGENCY_DETAILS")}
      </AnimatedButton>
    </Paper>
  )
}

export default CreateBrokerAccount