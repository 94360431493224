import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";

import CustomDatePicker from "components/Datepicker/Datepicker";
import FilterComponent from "components/common/FilterComponent";
import AnimatedButton from "components/custom/AnimatedButton";
import { motion } from "framer-motion";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import { fromDate, toDate } from "utils/utils";

interface FiltersContainerProps {
  title?: React.ReactNode;
  filters: Filter[];
  onDateChange?: (newValue: Dayjs, name: string, filters: Filter[]) => void;
  extraFilters?: React.ReactNode;
  onApply: (filters: Filter[]) => void;
  onClear: (filters: Filter[]) => void;
  onFiltersInput?: (key: string, value: string) => void;
}

const filterVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0, height: 0, display: "none" },
};



const FiltersContainer: FunctionComponent<FiltersContainerProps> = ({
  title,
  filters,
  onDateChange,
  onApply,
  onClear,
  extraFilters,
  onFiltersInput,
}) => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const defaultState = filters.map((f) => ({
    ...f,
    options: [...(f.options ?? [])?.map((o) => ({ ...o, isSelected: true }))],
    allSelected: true,
  }));

  const [filtersChanged, setFiltersChanged] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [filterData, setFilterData] = useState<Filter[]>(defaultState);

  const toggleFilters = () => setShowFilters(!showFilters);

  const hasMoreThanOneOption = (filterId: string) => {
    const currentFilter = defaultState?.filter((d) => d.id === filterId)?.[0];
    return currentFilter?.options.length > 1
  }

  const onChange = (newSelection: SelectOption, filterId: string) => {
    if (hasMoreThanOneOption(filterId)) {
      setFilterData((data) =>
        data.map((d) =>
          d.id === filterId
            ? {
              ...d,
              options: d.options.map((o) =>
                o.value === newSelection.value
                  ? {
                    ...o,
                    isSelected: !o.isSelected,
                  }
                  : o
              ),
            }
            : d
        )
      );
      setFiltersChanged(true);
    }
  };

  const handleOnApplyClick = () => {
    onApply(filterData);
    setFiltersChanged(false);
  };

  const handleOnClearClick = () => {
    setFilterData(defaultState);
    onClear(defaultState);
  };

  const toggleSelectAll = (filterId: string) => {
    if (hasMoreThanOneOption(filterId)) {
      const isEveryOptionSelected = filterData
        .find((d) => d.id === filterId)
        .options.every((o) => o.isSelected);

      setFilterData((data) => [
        ...data.map((d) =>
          d.id === filterId
            ? {
              ...d,
              options: d.options.map((o) => ({
                ...o,
                isSelected: !isEveryOptionSelected,
              })),
              allSelected: !isEveryOptionSelected,
            }
            : d
        ),
      ]);
      setFiltersChanged(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {title}
        <Box>{extraFilters}</Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            ml: "auto",
          }}
        >
          {onDateChange && (
            <>
              <CustomDatePicker
                name="start_date"
                label={t("FILTERS.fromDate")}
                value={fromDate}
                onDateChange={(value) =>
                  onDateChange(value, "start_date", filterData)
                }
              />

              <CustomDatePicker
                name="end_date"
                label={t("FILTERS.toDate")}
                value={toDate}
                onDateChange={(value) =>
                  onDateChange(value, "end_date", filterData)
                }
              />
            </>
          )}
          {filters.length > 0 && (
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                fontSize: "14px",
                px: "12px",
                py: "6px",
                mt: "6px",
                borderRadius: "4px",
                background:
                  customStyles?.muiTheme?.palette?.background?.default,
              }}
              onClick={toggleFilters}
            >
              <FilterListIcon sx={{ fontSize: "32px", mr: "2px" }} />
              <Typography>
                {showFilters ? t("FILTERS.hide") : t("FILTERS.filter")}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
        }}
        component={motion.div}
        variants={filterVariants}
        animate={showFilters ? "open" : "closed"}
        transition={{ type: "tween" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "40px",
          }}
        >
          {filterData.map((filter) => (
            <FilterComponent
              key={filter.id}
              {...filter}
              toggleSelectAll={() => {
                toggleSelectAll(filter.id);
              }}
              onChange={(optionsSelected) =>
                onChange(optionsSelected, filter.id)
              }
              {...(onFiltersInput ? { onInputChange: (value) => onFiltersInput(filter.id, value) } : {})}
            />
          ))}
        </Box>
        {!filters.every(filter => filter.options.length === 1) && (
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ display: "flex", gap: "20px" }}>
              <AnimatedButton
                size="medium"
                sx={{ textTransform: "none" }}
                variant="contained"
                disabled={!filtersChanged}
                onClick={handleOnApplyClick}
              >
                {t("FILTERS.apply")}
              </AnimatedButton>
              <AnimatedButton
                size="medium"
                sx={{ textTransform: "none" }}
                variant="outlined"
                onClick={handleOnClearClick}
              >
                {t("FILTERS.clearAll")}
              </AnimatedButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FiltersContainer;
