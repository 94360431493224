import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import PopoverComponent from "../PopoverComponent";
import AnimatedButton from "components/custom/AnimatedButton";

interface DynamicFilterComponentProps {
  value: string[];
  title?: string;
  onChange: (value: string[]) => void;
  options: {
    label: string;
    value: string;
  }[];
  onInputChange?: (value: string) => void;
  isLoading?: boolean;
  onOpenChange?: (value: boolean) => void
}

const DynamicFilterComponent: React.FC<DynamicFilterComponentProps & Filter> = ({
  value: selectedValues,
  title,
  options,
  onChange,
  onInputChange,
  isLoading = false,
  onOpenChange,
}) => {

  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [localSearch, setLocalSearch] = React.useState('');


  const filterOptions = React.useMemo(() => {
    if (localSearch) {
      return options.filter((o) =>
        o.value.toLowerCase().includes(localSearch.toLowerCase())
      );
    }
    return options;
  }, [localSearch, options]);

  const addValue = (value: string) => {
    const updatedValues = [...selectedValues, value];
    onChange(updatedValues);
  };
  const removeValue = (value: string) => {
    const updatedValue = selectedValues.filter((v) => v !== value);
    onChange(updatedValue);
  };
  const removeAllValues = () => {
    onChange([]);
  };
  return (
    <PopoverComponent
      title={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            py: "5px",
            px: "12px",
            borderRadius: "4px",
            background: customStyles?.muiTheme?.palette?.background?.default,
          }}
        >
          <Typography sx={{ fontSize: "12px" }}>{title}</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>
              {`${selectedValues.length} ${t(
                "FILTERS.selected"
              )}`}
            </Typography>
            {isFilterOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Box>
      }
      setPopoverStatus={(value) => {
        setIsFilterOpen(value)
        onOpenChange?.(value)
        if (!value) {
          setLocalSearch('')
        }
      }}
    >
      <Box sx={{ p: "20px", maxWidth: "240px", overflowX: "hidden" }}>
        <TextField
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderLeft: "none",
              borderRight: "none",
            },
            ".MuiOutlinedInput-input": {
              fontSize: "14px",
              color: customStyles?.input?.color,
            },
          }}
          label={
            <Box
              sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
            >
              <SearchIcon fontSize="small" />
              <Typography>{t("SEARCH")}</Typography>
            </Box>
          }
          variant="outlined"
          placeholder={title}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (onInputChange) {
              // dynamic search via API
              onInputChange(e.target?.value);
            } else {
              // local filter for options
              setLocalSearch(e.target?.value);
            }
          }}
        />
        <Divider key={"divider"} />
        {isLoading ? <Box
          sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box> : <>
          {filterOptions.length > 0 ? <Box
            sx={{
              maxHeight: "200px",
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              mt: "10px",
              flexDirection: "column",
            }}
          >
            {filterOptions.map((option) => {
              const isSelected = selectedValues.includes(option.value);
              return <FormGroup key={option.value}>
                <FormControlLabel
                  sx={{
                    wordBreak: "break-all",
                    ".MuiFormControlLabel-label": { fontSize: "14px" },
                  }}
                  key={option.value}
                  control={
                    <Checkbox
                      size="small"
                      checked={isSelected}
                      onChange={() => {
                        if (isSelected) {
                          removeValue(option.value);
                        } else {
                          addValue(option.value);
                        }
                      }}
                    />
                  }
                  label={option.label}
                />
              </FormGroup>
            })}
          </Box> : <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Typography>
              {t("NO_RESULTS_FOUND")}
            </Typography>
          </Box>}
          {selectedValues.length > 0 && <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <AnimatedButton onClick={removeAllValues}>{t("FILTERS.clearAll")}</AnimatedButton>
          </Box>}
        </>
        }

      </Box>
    </PopoverComponent>
  );
};

export default DynamicFilterComponent;
