import { Box, Grid, Typography } from "@mui/material";
import NotificationCard from "components/Notifications/NotificationCard";
import RequestMembershipCard from "components/Notifications/RequestmemberShipCard";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useT } from "translation";

function NotificationsList() {
  const { t } = useT();
  const invitedAccounts = useSelector(
    (state: RootState) => state.auth.accounts.invitedAccounts
  );
  const requestedMembership = useSelector(
    (state: RootState) => state.auth.accounts.membershipRequest
  )
  if (invitedAccounts.length === 0 && requestedMembership.length === 0) {
    return (
      <Box display="flex" justifyContent={"center"} alignItems={"center"}>
        <Typography variant="h5">
          {t("NO_NEW_NOTIFICATION_AVAILABLE")}
        </Typography>
      </Box>
    );
  }
  const renderInviteList = () => {
    if (invitedAccounts.length === 0) return null;
    return (
      <>
        <Typography variant="subtitle1" fontWeight={"bold"} mb={2}>{t("INVITES")}</Typography>
        <Grid item xs={12}>
          {invitedAccounts.map((acc) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <NotificationCard
                  description={acc.name}
                  timeStamp={new Date(acc.created_at)}
                  memberId={acc.member.id}
                  accountId={acc.id}
                />
              </Grid>
            );
          })}
        </Grid>
      </>
    )
  }
  const renderMembershipRequestList = () => {
    if (requestedMembership.length === 0) return null;
    return (
      <>
        <Typography variant="subtitle1" fontWeight={"bold"} mb={2}>{t("MEMBERSHIP_REQUEST")}</Typography>
        <Grid item xs={12}>
          {requestedMembership.map((membershipRequest) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <RequestMembershipCard
                  membershipRequest={membershipRequest}
                />
              </Grid>
            );
          })}
        </Grid>
      </>
    )
  }
  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        {renderMembershipRequestList()}
      </Grid>
      <Grid xs={12} item>
        {renderInviteList()}
      </Grid>
    </Grid>
  );
}

export default NotificationsList;
