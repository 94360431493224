import { Navigate, useLocation } from "react-router-dom";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import useDashboardStartScreen from "hooks/useDashboardStartScreen";

const AuthPageRoute = ({ children }) => {
  const location = useLocation();
  const dashboardStartScreen = useDashboardStartScreen()

  const IS_USER_AUTHENTICATED = !!getFromLocalStorage(LSKeys.ACCESS_TOKEN);

  if (IS_USER_AUTHENTICATED) {
    return <Navigate to={dashboardStartScreen} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default AuthPageRoute;

