import { Box, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import useDashboardStartScreen from "hooks/useDashboardStartScreen";
import { useAgencyValidationSchema } from "modules/Accounts/AdminOnBoarding";
import BrokerAlreadyExist from "modules/Accounts/BrokerAlreadyExist";
import { createAccountAPI, generateKeysForChildAccountAPI } from "modules/Accounts/api";
import { IAgencyDetails, ICreateAccountRes, IGenerateKeysRes } from "modules/Accounts/types";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useT } from "translation";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";


const initialAgencyDetails = {
  cnpj: "",
  licenseNumber: "",
  agencyName: "",
  agencyAddress: "",
};

type Props = {
  postCreateAccountAction?: (cb: Function) => void
  isPostAccountActionInProgress?: boolean
  postSendMembershipRequest?: () => void
}
function AgencyDetailForm({
  postCreateAccountAction,
  isPostAccountActionInProgress,
  postSendMembershipRequest
}: Props) {
  const dashboardStartScreen = useDashboardStartScreen()
  const { setToast } = useContext(ToastCtx);
  const navigate = useNavigate();
  const { t } = useT();
  const queryClient = useQueryClient();
  const agencyDetailsValidationSchema = useAgencyValidationSchema();

  const createAccountMutation = useMutation<
    ICreateAccountRes,
    any,
    IAgencyDetails
  >({
    mutationFn: createAccountAPI,
    onSuccess: (data) => {
      generateKeysMutation.mutate(data.id);
    },
    onError: ({ error: { code } }: { error: { code: string } }) => {
      if (code === "tax_id_already_exists") {
        return;
      }
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const generateKeysMutation = useMutation<IGenerateKeysRes, any, string>({
    mutationFn: generateKeysForChildAccountAPI,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: ["accounts", getFromLocalStorage(LSKeys.ACCESS_TOKEN)],
        })
        .then(() => {
          if (postCreateAccountAction) {
            postCreateAccountAction(() => {
              navigate(dashboardStartScreen, { replace: true });
            })
          }
          else {
            navigate(dashboardStartScreen, { replace: true });
          }
        });
    },
    onError: () => {
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const handleSave = (values: typeof initialAgencyDetails) => {
    const createAccountData = {
      name: values.agencyName,
      regulatory_info: [
        { tax_id: values.cnpj },
        ...(values.licenseNumber ? [{ license: values.licenseNumber }] : []),
      ],
      address: values.agencyAddress,
    };
    createAccountMutation.mutate(createAccountData);
  };

  const {
    isBrokerDetailsAlreadyExist,
    agencyName,
    adminName,
    adminEmail
  } = useMemo(() => {
    if (createAccountMutation?.error?.error?.code === "tax_id_already_exists") {
      const adminUser = createAccountMutation?.error?.error?.response?.member?.user;
      return {
        isBrokerDetailsAlreadyExist: true,
        agencyName: createAccountMutation?.error?.error?.response?.intermediary?.name,
        adminName: `${adminUser?.first_name} ${adminUser?.last_name}`,
        adminEmail: createAccountMutation?.error?.error?.response?.member?.email
      }
    }
    return {
      isAccountAlreadyExist: false,
      agencyName: '',
      adminName: '',
      adminEmail: '',
    }
  }, [createAccountMutation?.error]);

  return (
    <>
      {isBrokerDetailsAlreadyExist && <BrokerAlreadyExist
        agencyName={agencyName}
        adminName={adminName}
        adminEmail={adminEmail}
        postSendMembershipRequest={postSendMembershipRequest}
      />}

      <Formik
        initialValues={initialAgencyDetails}
        validationSchema={agencyDetailsValidationSchema}
        validateOnMount={true}
        onSubmit={handleSave}
      >
        {({ isValid, values }) => {
          return <Form>
            <Box
              display={"flex"}
              flexDirection="column"
              alignItems={"flex-start"}
              gap={"4px"}
            >
              <>
                <Field
                  as={TextField}
                  label={t("AGENCY_NAME")}
                  variant="outlined"
                  name="agencyName"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      width: "100%",
                    },
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="agencyName"
                  component="div"
                />
              </>
              <>
                <Field
                  as={TextField}
                  label={t("TAX_ID")}
                  variant="outlined"
                  name="cnpj"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                    },
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="cnpj"
                  component="div"
                />
              </>
              <>
                <Field
                  as={TextField}
                  label={t("AGENCY_ADDRESS")}
                  variant="outlined"
                  name="agencyAddress"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                    },
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="agencyAddress"
                  component="div"
                />
              </>
              <>
                <Field
                  as={TextField}
                  fullWidth
                  label={t("LICENSE_NUMBER")}
                  variant="outlined"
                  name="licenseNumber"
                  margin="normal"
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                    },
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="licenseNumber"
                  component="div"
                />
              </>
              <AnimatedButton
                variant="contained"
                color="primary"
                type="submit"
                isLoading={createAccountMutation.isPending ||
                  isPostAccountActionInProgress ||
                  generateKeysMutation.isPending}
                sx={{ mt: 2 }}
                disabled={!isValid}
              >
                {t("SAVE")}
              </AnimatedButton>
            </Box>
          </Form>
        }}
      </Formik>
    </>
  )
}

export default AgencyDetailForm;