import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Chip, Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router";

import { useMedia } from "hooks/useMedia";
import { useT } from "translation";
import { Policy, PolicyStatus } from "types/policy";
import { capitalizeFirstLetter } from "utils/utils";
import { ProductIconMapping } from "assets/IconMappings";
import { InsurerNameMapping } from "components/Quote/sections/utils";

type PolicyListItemProps = {
  policy: Policy;
};

export function PolicyListItem({ policy }: PolicyListItemProps) {
  const { t } = useT();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isMobile, isTab } = useMedia();
  const productName = policy.product.split("-")?.[0];
  const insurerName = policy.product.split("-")?.[1];
  const isPolicyActive =
    policy.status === PolicyStatus.ACTIVE ||
    policy.status === PolicyStatus.INITIATED ||
    policy.status === PolicyStatus.DOCUMENTS_GENERATED;

  const ProductIcon =
    ProductIconMapping[productName as keyof typeof ProductIconMapping];

  const startDate = policy.start_date?.split("T")?.[0];
  const endDate = policy.end_date?.split("T")?.[0];

  return (
    <Grid
      container
      style={{
        backgroundColor: "Background",
        padding: "24px",
        borderRadius: "12px",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => navigate(policy.id)}
    >
      <Grid item lg={3} md={3} sm={8} xs={8}>
        <Box sx={{ display: "flex", gap: "12px" }}>
          <Box
            sx={{
              display: "flex",
              bgcolor: "primary.main",
              alignItems: "center",
              alignSelf: "center",
              p: "14px",
              borderRadius: "8px",
            }}
          >
            {/* @ts-ignore */}
            {ProductIcon && <ProductIcon
              fill={theme?.palette?.primary?.contrastText}
              height={25}
              width={26}
            />}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h4">
              {capitalizeFirstLetter(productName)}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                textOverflow: "ellipsis",
              }}
            >
              <Typography variant="subtitle2" sx={{ color: "secondary.main" }}>
                {policy.policy_number}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      {isMobile || isTab ? null : (
        <>
          <Grid item lg={3} md={3} sm={0} xs={0}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6">
                {`${startDate} - ${endDate}`}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: "secondary.main" }}>
                {t("POLICY_TERMS")}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={2} md={2} sm={0} xs={0}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6">
                {InsurerNameMapping[insurerName]}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: "secondary.main" }}>
                {t("INSURER")}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={2} md={2} sm={0} xs={0}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {/* TODO: Remove this hardcoded value */}
              <Typography variant="h6">{policy.broker_on_record}</Typography>
              <Typography variant="subtitle2" sx={{ color: "secondary.main" }}>
                {t("BROKER_ON_RECORD")}
              </Typography>
            </Box>
          </Grid>
        </>
      )}
      <Grid item lg={2} md={2} sm={4} xs={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            gap: "12px",
          }}
        >
          <Chip
            label={isPolicyActive ? t("ACTIVE") : t("INACTIVE")}
            color={isPolicyActive ? "success" : "error"}
          />
          <ChevronRightIcon fontSize="medium" color="secondary" />
        </Box>
      </Grid>
    </Grid>
  );
}
