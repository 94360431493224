import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import OutletHeader from "components/Home/Common/OutletHeader";
import NotificationsList from "components/Notifications/NotificationsList";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { useEffect } from "react";
import { useT } from "translation";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from "react-router-dom";
import useDashboardStartScreen from "hooks/useDashboardStartScreen";

const Notifications = () => {
  const { t } = useT();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dashboardStartScreen = useDashboardStartScreen();
  const location = useLocation();
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["accounts", getFromLocalStorage(LSKeys.ACCESS_TOKEN)],
    });
  }, [queryClient]);
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "12px",
      }}
      {...animateRouteProps}
    >
      <AnimatedButton
        sx={{ mb: 3 }}
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(location.key === "default" ? dashboardStartScreen : -1 as unknown as string)}
      >
        {t("BACK")}
      </AnimatedButton>
      <OutletHeader title={t("NOTIFICATION_PAGE.title")} />
      <NotificationsList />
    </Box>
  );
};

export default Notifications;
