import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ApplicationConfig,
  SectionHeaderInfo,
} from "stere-insurance-sdk/dist/types";

import { ClientProduct } from "customTypes/ClientProducts";

interface ClientState {
  clientAPIKey: string;
  clientUrl: string;
  noQuoteText: string;
  customerId: string;
  applicantId: string;
  productSelection: string[];
  availableProducts: ClientProduct[];
  startPageSelection: ClientProduct[];
  plansSelected: any[];
  loading: boolean;
  loadingClientFeatures: boolean;
  sectionHeaderInfoConfig: SectionHeaderInfo[];
  invalidProductCombinations: string[];
  showPaymentSummaryPage: boolean;
  showAutoRenewalSwitch: boolean;
  errorAndShowExitBtn: boolean;
  clientFeatures: ClientFeatures;
  simulateErrorConfig: SimulateErrorConfig;
  applicationConfig: ApplicationConfig;
}

const initialState: ClientState = {
  clientAPIKey: "",
  clientUrl: "",
  noQuoteText: "",
  customerId: "",
  applicantId: "",
  productSelection: [],
  availableProducts: [],
  startPageSelection: [],
  plansSelected: [],
  loading: false,
  loadingClientFeatures: false,
  sectionHeaderInfoConfig: [],
  invalidProductCombinations: [],
  showPaymentSummaryPage: true,
  showAutoRenewalSwitch: false,
  errorAndShowExitBtn: false,
  clientFeatures: null,
  simulateErrorConfig: null,
  applicationConfig: null,
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    saveClientSentInfo(state, action: PayloadAction<any>) {
      state.clientAPIKey = action.payload["X-API-KEY"];
      state.applicantId = action.payload.applicant_id;
      state.productSelection = action.payload.product_selection;
    },
    saveNoQuoteText(state, action: PayloadAction<string>) {
      state.noQuoteText = action.payload;
    },
    fetchAvailableProducts(state) {
      state.loading = true;
    },
    setAvailableProducts(state, action: PayloadAction<ClientProduct[]>) {
      state.availableProducts = action.payload;
      state.loading = false;
    },
    setLoadingValue(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateStartPageSelection(state, action: PayloadAction<ClientProduct[]>) {
      state.startPageSelection = action.payload;
    },
    setCustomerId(state, action: PayloadAction<string>) {
      state.customerId = action.payload;
    },
    setClientUrl(state, action: PayloadAction<string>) {
      state.clientUrl = action.payload;
    },
    setSectionHeaderInfoConfig(
      state,
      action: PayloadAction<SectionHeaderInfo[]>
    ) {
      state.sectionHeaderInfoConfig = action.payload;
    },
    addInvalidProductCombinations(state, action: PayloadAction<string>) {
      state.invalidProductCombinations = [
        ...state?.invalidProductCombinations,
        action.payload,
      ];
    },
    removeInvalidProductCombinations(state, action: PayloadAction<string>) {
      state.invalidProductCombinations = state?.invalidProductCombinations.filter(
        (p) => p !== action?.payload
      );
    },
    setShowPaymentSummaryPage(state, action: PayloadAction<boolean>) {
      state.showPaymentSummaryPage = action.payload;
    },
    setShowAutoRenewalSwitch(state, action: PayloadAction<boolean>) {
      state.showAutoRenewalSwitch = action.payload;
    },
    setErrorAndShowExitBtn(state, action: PayloadAction<boolean>) {
      state.errorAndShowExitBtn = action.payload;
    },
    fetchClientFeatures(state) {
      state.loadingClientFeatures = true;
    },
    setClientFeatures(state, action: PayloadAction<ClientFeatures>) {
      state.clientFeatures = action.payload;
      state.loadingClientFeatures = false;
    },
    setSimulateErrorConfig(state, action: PayloadAction<SimulateErrorConfig>) {
      state.simulateErrorConfig = action.payload;
    },
    setApplicationConfig(state, action: PayloadAction<ApplicationConfig>) {
      state.applicationConfig = action.payload;
    },
  },
});

export const {
  saveClientSentInfo,
  saveNoQuoteText,
  fetchAvailableProducts,
  setAvailableProducts,
  setLoadingValue,
  updateStartPageSelection,
  setCustomerId,
  setClientUrl,
  setSectionHeaderInfoConfig,
  addInvalidProductCombinations,
  removeInvalidProductCombinations,
  setShowPaymentSummaryPage,
  setShowAutoRenewalSwitch,
  setErrorAndShowExitBtn,
  fetchClientFeatures,
  setClientFeatures,
  setSimulateErrorConfig,
  setApplicationConfig,
} = clientSlice.actions;
export default clientSlice.reducer;
