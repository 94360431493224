import jwtDecode, { JwtPayload } from "jwt-decode";

export const ACCESS_TOKEN_KEY = "ACCESS_TOKEN";
export const REFRESH_TOKEN_KEY = "REFRESH_TOKEN";
export const ID_TOKEN_KEY = "ID_TOKEN";

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const setAccessToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, `Bearer ${token}`);

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const setRefreshToken = (token: string) =>
  localStorage.setItem(REFRESH_TOKEN_KEY, token);

export const getIDToken = () => localStorage.getItem(ID_TOKEN_KEY);

export const setIDToken = (token: string) =>
  localStorage.setItem(ID_TOKEN_KEY, token);

export const removeAllTokens = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(ID_TOKEN_KEY);
};

export const getDecodedJwtToken = (): JwtPayload => {
  const token = getAccessToken();
  if (!!token) {
    return jwtDecode(token);
  }
  return null;
};
