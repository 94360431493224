import { Box, Dialog, DialogContent, DialogTitle, Grid, Paper, Typography, styled } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { sendMemberShipRequestAPI } from "modules/Accounts/api";
import useUpdateRole from "modules/Agents/useUpdateRole";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { signOut } from "store/features/authSlice";
import { useT } from "translation";

type Props = {
  agencyName: string;
  adminName: string;
  adminEmail: string;
  postSendMembershipRequest: () => void
}

const StyledAgencyDetailTypography = styled(Typography)`
  text-overflow: ellipsis;
  max-width: 15ch;
  overflow: hidden;
  font-weight: bold;
  white-space: nowrap;
`;

function BrokerAlreadyExist({ agencyName, adminName, adminEmail, postSendMembershipRequest }: Props) {
  const dispatch = useDispatch();
  const [confirmRequestDialog, setConfirmRequestDialog] = useState(false);
  const { t } = useT()
  const handleReLoginClick = () => {
    dispatch(signOut());
  }
  const { setToast } = useContext(ToastCtx);
  const { changeToAgent, isLoading } = useUpdateRole();

  const { mutate: sendMembershipRequest, isPending: isMembershipRequestLoading } = useMutation<unknown, any, string>({
    mutationFn: sendMemberShipRequestAPI,
    onSuccess: () => {
      setToast({
        severity: "success",
        text: t("MEMBERSHIP_REQUEST_SENT"),
      });
      postSendMembershipRequest()
    },
    onError: ({ error: { code } }: { error: { code: string } }) => {
      if (code === "admin_not_found") {
        setToast({
          severity: "error",
          text: t("ADMIN_NOT_FOUND"),
        });
        return;
      }
      if (code === "membership_already_requested") {
        setToast({
          severity: "error",
          text: t("MEMBERSHIP_ALREADY_REQUESTED"),
        });
        return;
      }
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const closeConfirmDialog = () => setConfirmRequestDialog(false);
  const handleSendRequest = () => {
    changeToAgent(() => {
      sendMembershipRequest(adminEmail);
    })
  }

  return (
    <Paper sx={{ px: 2, py: 1, textAlign: "left", mb: 2 }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{t("BROKER_ACCOUNT_ALREADY_EXISTS")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{t("BROKER_ACCOUNT_WITH_SAME_CNPJ_EXISTS")}</Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography variant="caption">{t("ACCOUNT_DETAILS")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
            <Box sx={{ display: "flex", gap: 3 }}>
              {agencyName && <Box sx={{ display: "flex", flexDirection: "column" }}>
                <StyledAgencyDetailTypography variant="body2">{agencyName}</StyledAgencyDetailTypography>
                <Typography variant="caption">{t("AGENCY")}</Typography>
              </Box>}
              {adminName && <Box sx={{ display: "flex", flexDirection: "column" }}>
                <StyledAgencyDetailTypography variant="body2" sx={{ fontWeight: "bold" }}>{adminName}</StyledAgencyDetailTypography>
                <Typography variant="caption">{t("ADMIN")}</Typography>
              </Box>}
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <AnimatedButton
                onClick={handleReLoginClick}>
                {t("BACK_TO_LOGIN")}
              </AnimatedButton>
              {adminEmail && (
                <AnimatedButton
                  variant="contained"
                  onClick={() => setConfirmRequestDialog(true)}
                  isLoading={isMembershipRequestLoading}
                >
                  {t("REQUEST_TO_JOIN_ACCOUNT")}
                </AnimatedButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Dialog onClose={closeConfirmDialog} open={confirmRequestDialog} fullWidth>
        <DialogTitle>{t("REQUEST_TO_JOIN")}</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} mb={2}>
              <Typography variant="body1">
                {t("PROCEEDING_WILL_SEND_REQUEST")}
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              <Box sx={{ display: "flex", gap: 2 }}>
                <AnimatedButton
                  variant="outlined"
                  onClick={closeConfirmDialog}
                >
                  {t("POP_UPS.BACK_TO_DASHBOARD_POPUP.title")}
                </AnimatedButton>
                <AnimatedButton
                  variant="contained"
                  onClick={handleSendRequest}
                  isLoading={isLoading}
                >
                  {t("POP_UPS.BACK_TO_DASHBOARD_POPUP.btnText")}
                </AnimatedButton>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Paper>
  )
}

export default BrokerAlreadyExist;