import { TranslationKeys } from "./types";

export class TranslationEs implements TranslationKeys {
  public SEARCH = "Buscar";
  public SETTINGS = "Ajustes";
  public LOGOUT = "Cerrar sesión";
  // Home Navigations
  public DASHBOARD = "Panel";
  public QUOTES = "Citas";
  public POLICIES = "Políticas";
  public INSURANCE_PRODUCTS = "Productos de seguros";
  public MY_APPOINTMENTS = "Mi cita";
  public NEW_QUOTE = "Nueva cotización";
  public APPLICATIONS = "Aplicaciones";
  public CLAIMS = "Reclamos";
  // Dashboard Page
  public QUOTES_SENT = "Cotizaciones enviadas";
  public POLICIES_ISSUED = "Pólizas emitidas";
  public COMMISSION_EARNED = "Comisión estimada";
  // Applications Page
  public APPLICATIONS_PAGE = {
    title: "Aplicaciones",
    desc: "Aplicaciones en curso",
    tableHeaders: {
      application_id: "ID de aplicación",
      applicant_name: "Nombre del solicitante",
      applicant_email: "Correo electrónico del solicitante",
      business_name: "Nombre del negocio",
      date: "Fecha",
      quote: "Cotización",
      status: "Estado",
      insurance: "Seguro",
      action: "Acción",
    },
  };
  public AGENTS_PAGE = {
    title: "Todos los agentes",
    inviteBtn: "Invitar",
    status: "Estado",
    send: "Enviar",
    inviteDialogHeading: "Invitar agente",
    agentsTabView: {
      myAgents: "mis agentes",
      sentInvites: "invitaciones enviadas",
    },
  };
  // Quotes Page
  public QUOTES_PAGE = {
    title: "Citas",
    desc: "Cotizaciones enviadas al asegurado",
    tableHeaders: {
      quote_id: "ID de cotización",
      application_id: "ID de aplicación",
      applicant_name: "Nombre del solicitante",
      business_name: "Nombre del negocio",
      date: "Fecha",
      quote: "Cotización",
      status: "Estado",
      insurance: "Seguro",
      action: "Acción",
    },
    status: {
      quoteSent: "Cotización enviada",
      quotesSent: "Cotizaciones enviadas",
      noQuoteSent: "No se envió cotización",
      paymentMade: "Pago realizado",
      quotesGenerated: "Cotizaciones generadas",
      noQuoteGenerated: "No se generaron cotizaciones",
    },
  };
  public APPLICATION_PAGE = {
    status: {
      draft: "Borrador",
      submitted: "Enviado",
    },
  };
  // Policies Page
  public POLICIES_PAGE = {
    title: "Políticas",
    desc: "Pólizas emitidas",
    tableHeaders: {
      policy_num: "Número de póliza",
      policy_holder: "Titular de la póliza",
      insurance: "Seguro",
      insurer: "Asegurador",
      commission: "Comisión",
      renewal_date: "Fecha de renovación",
      status: "Estado",
      action: "Acción",
    },
    status: {
      active: "Activo",
    },
  };
  // Insurance Products Page
  public I_PRODUCTS_DESCRIPTION_PRIMARY = "Productos de seguros disponibles para cotizar";
  // Common
  public BACK = "Atrás";
  public NEXT = "Próximo";
  public CONTINUE = "Continuar";
  public COMPLETE = "Completo";
  public COMING_SOON = "Muy pronto...";
  public BACK_TO_DASHBOARD = "Volver al panel";
  public CANCEL = "Cancelar";
  public EXIT = "Salida";
  public SAVE = "Ahorrar";
  public PAYMENT_FREQUENCY_MONTHLY = "Mensual";
  public PAYMENT_FREQUENCY_YEARLY = "Anual";
  public FREQUENCY_MONTH = "mes";
  public FREQUENCY_YEAR = "año";
  // Loader
  public GLOBAL_LOADER_MESSAGE = "Preparando las cosas para ti...";
  public FETCHING_APPLICATION = "Recuperando su solicitud...";
  public LOADING_DOCUMENT_FOR_SIGN = "Loading documents for sign ...";
  public FETCHING_DOCU_SIGN_STATUS = "Recuperando su solicitud...";
  public GETTING_CONFIRMATION = "Obteniendo confirmación...";
  public LOADING_PAYMENT_WIDGET = "Cargando widget de pago...";
  public FETCHING_PRODUCTS = "Obteniendo todos los productos...";
  public FETCHING_PLANS = "Encontrar los planes más adecuados. Nosotros apreciamos su paciencia...";
  public SUBMITTING_RESPONSE = "Enviando su respuesta...";
  public FETCHING_QUOTES = "Obteniendo sus cotizaciones...";
  // Register page
  public REGISTER = "Registro";
  public EnterDetails = "Por favor ingresa tus datos para continuar";
  public UnableToRegisterUser = "¡No se puede registrar el usuario!";
  // SignIn page
  public SIGN_IN = "Iniciar sesión";
  public SIGN_IN_MESSAGE = "Por favor ingresa tu correo electrónico y contraseña";
  public SIGN_IN_MESSAGE_V2 = "Por favor ingrese su correo electrónico";
  public OTP_MESSAGE = "Por favor ingrese el One-time password enviado a su correo electrónico";
  public REMEMBER_ME = "Acuérdate de mí";
  public FORGOT_PASSWORD = "¿Has olvidado tu contraseña?";
  public SIGN_IN_BUTTON_TEXT = "Iniciar sesión";
  public EMAIL_LABEL = "Correo electrónico";
  public FULL_NAME_LABEL = "Nombre completo";
  public EMAIL_REQUIRED = "correo electronico es requerido";
  public INVALID_EMAIL = "Dirección de correo electrónico no válida";
  public PASSWORD_LABEL = "Contraseña";
  public PASSWORD_REQUIRED = "se requiere contraseña";
  public FULL_NAME_REQUIRED = "Se requiere nombre completo";
  public INVALID_PASSWORD = "La contraseña debe tener al menos 8 caracteres";
  public FORGOT_PASSWORD_MESSAGE = "Ingrese su correo electrónico registrado para establecer una nueva contraseña";
  public RECOVER_PASSWORD = "Recuperar contraseña";
  public PASSWORD_CREATED = "Contraseña creada";
  public PASSWORD_CREATED_DESCRIPTION_PRIMARY = "Su contraseña ha sido creada exitosamente";
  public CREATE_PASSWORD = "Crear contraseña";
  public CREATE_NEW_PASSWORD = "Crear nueva contraseña";
  public CREATE_NEW_PASSWORD_DESCRIPTION_PRIMARY = "Por favor elija una nueva contraseña";
  // Stepper
  public Start = "Comenzar";
  public Knockout = "Knockear";
  public Application = "Solicitud";
  public Quote = "Cita y resumen";
  public Payment = "Pago";
  public Confirmation = "Confirmación";
  // Quote Start Page
  public START_PAGE_DESCRIPTION_PRIMARY = "¡Consigue el mejor precio con nosotros, al instante!";
  public START_PAGE_DESCRIPTION_SECONDARY = "Seleccione uno o más productos para iniciar su solicitud.";
  public START_PAGE_TABS = {
    PERSONAL: "Personal",
    BUSINESS: "Negocio",
  };

  // Customer details page
  public CustomerDetails = "Detalles del cliente";
  public CUSTOMER_DETAILS_PAGE_DESCRIPTION_PRIMARY = "Detalles del cliente";
  public CUSTOMER_DETAILS_PAGE_DESCRIPTION_SECONDARY = "Ingresar Cliente";
  public CUSTOMER_DETAILS_FORM_EMAIL_LABEL = "Ingrese el correo electrónico del Cliente";

  public KNOCKOUT_FORM_PAGE_HEADING = "Solicitud";
  public KNOCKOUT_LETS_GET_STARTED = "Empecemos";
  public KNOCKOUT_FORM_PAGE_HEADING_JWL = "";
  public KNOCKOUT_FORM_PAGE_SUBHEADING = "¡Esto te llevará menos de 60 segundos!";
  public CHOOSE_A_PLAN = "Elige un plan";
  public SELECT_PAYMENT_FREQUENCY = "Selecciona tu frecuencia de pago";

  // Application Page
  public APPLICATION_PAGE_DESCRIPTION_PRIMARY = "¡No te preocupes, todos tus datos están a salvo con nosotros!";
  public APPLICATION_PAGE_DESCRIPTION_SECONDARY =
    "Responde todas las preguntas lo mejor que pueda (* los campos marcados son obligatorios).";
  public APPLICATION_PAGE_SELECTED_INSURANCE = "Seguros Seleccionados";
  public APPLICATION_PAGE_QUOTES_FROM_INSURER = "Cotizaciones de la aseguradora";
  // Quotes Page
  public QUOTE_PAGE_DESCRIPTION_PRIMARY = "¡Nuestros precios harán que tu billetera baile feliz!";
  public QUOTE_PAGE_DESCRIPTION_SECONDARY = "Por favor asegúrese de que todos los detalles estén en orden.";
  public QUOTE_PAGE_COVERAGES = "Cobertura";
  public QUOTE_PAGE_COVERAGE_LIMIT = "Límite:";
  public QUOTE_PAGE_COVERAGE_DEDUCTIBLE = "Deducible:";
  public QUOTE_PAGE_SELECT_ONE_PLAN = "(Seleccione un plan para ver los detalles de su cobertura)";
  public QUOTE_PAGE_SUMMARY = "Resumen";
  public QUOTE_PAGE_SEND_QUOTES = "Enviar cotizaciones";
  public QUOTE_PAGE_SEND_PROPOSAL = "Enviar propuesta";
  public QUOTE_PAGE_DOWNLOAD_QUOTE_PDF = "Descargar cotización pdf";
  public QUOTE_PAGE_SHARE_LINK = "Compartir enlace";
  public QUOTE_PAGE_SUCCESS_MESSAGE = "¡Cotizaciones enviadas exitosamente!";
  // Quotes Snapshot Page
  public QUOTES_SNAPSHOT_DESCRIPTION_PRIMARY = (applicantName: string) => {
    return `Hola ${applicantName} aquí está tu cotización`;
  };
  public QUOTES_SNAPSHOT_DESCRIPTION_SECONDARY =
    "La prima estimada que ha recibido se basa en los tipos de pólizas que ha seleccionado y la información que nos ha proporcionado.";
  public QUOTES_SNAPSHOT_MAKE_PAYMENT = "Hacer el pago";
  public QUOTES_SNAPSHOT_PAYMENT_DONE = "Pagado";
  public QUOTES_SNAPSHOT_PENDING_PAYMENT_STATUS = "Pendiente de finalización";
  public QUOTES_SNAPSHOT_PAID_PAYMENT_STATUS = "Pago completo";
  public QUOTES_TOTAL_PREMIUM = "Prima total";
  public QUOTES_TOTAL_PREMIUM_JWL = "";
  // Payment Page
  public PAYMENT_PAGE_TOTAL_DUE = "Total adeudado";
  public PAYMENT_PAGE_BREAKDOWN = "Descomponer";
  public PAYMENT_PAGE_INSURANCE = "Seguro";
  public PAYMENT_PAGE_INSURER = "Asegurador";
  public PAYMENT_PAGE_PREMIUIM = "Prima";
  public PAYMENT_PAGE_BASIC_PREMIUM = "Prima Básica";
  public PAYMENT_PAGE_PREMIUIM_WITHOUT_VAT = "Prima (sin IVA)";
  public PAYMENT_PAGE_TAX = "Impuesto";
  public PAYMENT_PAGE_TAX_JWL = "Tax incl. Surplus Lines tax and state fees";
  public PAYMENT_PAGE_GST = "gst";
  public PAYMENT_PAGE_DESCRIPTION_PRIMARY = "El mejor precio, hecho a tu medida";
  public PAYMENT_PAGE_DESCRIPTION_SECONDARY = "Haga clic en el botón 'Realizar pago' para procesar";
  public PAYMENT_PAGE_TRANSACTION_FEE = "Tarifa de transacción";
  public PAYMENT_PAGE_SECURE_PAYMENT = "Pago seguro";
  public CHECKOUT_FORM_AUTO_RENEWAL_CONSENT = "Consentimiento de renovación automática";
  public PAYMENT_PAGE_MAKE_PAYMENT = "Hacer el pago";
  public PAYMENT_PAGE_RETRY_PAYMENT = "Reintentar pago";
  public PAYMENT_PAGE_SUCCESS_MESSAGE = "¡Pago exitoso!";
  public PAYMENT_PAGE_FAILURE_MESSAGE = "¡Pago fallido!";
  public PAYMENT_COMPLETE = "Pago completo";
  public PAYMENT_PENDING = "Pendiente de finalización";
  public PROCESSING_PAYMENT = "Procesando el pago...";
  public DO_NOT_CLOSE_WINDOW = "Por favor no cierres la ventana.";
  // Checkout Form
  public CHECKOUT_FORM_CARD = "Tarjeta";
  public CHECKOUT_FORM_NAME_ON_CARD = "Nombre en la Tarjeta";
  public CHECKOUT_FORM_CARD_NUMBER = "Número de tarjeta";
  public CHECKOUT_FORM_CARD_EXPIRY = "Caducidad de la tarjeta";
  public CHECKOUT_FORM_CVV = "CVV";
  public CHECKOUT_FORM_EMAIL = "Correo electrónico";
  // Settings Page
  public SETTINGS_PAGE_DESCRIPTION_PRIMARY = "¿Necesitas actualizar alguna información?";
  public SETTINGS_PAGE_TAB_COMPANY = "Compañía";
  public SETTINGS_PAGE_TAB_PERSONAL = "Personal";
  public SETTINGS_PAGE_TAB_AGENCY = "Agencia";
  public SETTINGS_PAGE_TAB_CHANGE_PASSWORD = "Cambiar la contraseña";
  // Company Form
  public COMPANY_FORM_COMPANY_NAME = "nombre de empresa";
  public COMPANY_FORM_CNPJ = "CNPJ";
  public COMPANY_FORM_REGISTRATION_NUMBER = "Número de registro";
  public COMPANY_FORM_DATE_OF_INCORPORATION = "Fecha de incorporación";
  public COMPANY_FORM_COMPANY_NAME_REQUIRED = "El nombre de la empresa es obligatorio";
  public COMPANY_FORM_CNPJ_REQUIRED = "Se requiere CNPJ";
  public COMPANY_FORM_REGISTRATION_NUMBER_REQUIRED = "Se requiere número de registro";
  public COMPANY_FORM_DATE_OF_INCORPORATION_REQUIRED = "Se requiere fecha de incorporación";
  // Personal Form
  public PERSONAL_FORM_FULL_NAME = "Nombre completo";
  public PERSONAL_FORM_CPF = "CPF";
  public PERSONAL_FORM_EMAIL = "Correo electrónico";
  public PERSONAL_FORM_PHONE = "Teléfono";
  public PERSONAL_FORM_FULL_NAME_REQUIRED: "Full name is required";
  public PERSONAL_FORM_INVALID_PHONE = "Ingrese un número de teléfono válido (11 dígitos)";
  public PERSONAL_FORM_PHONE_REQUIRED = "Se requiere teléfono";
  // Change Password
  public CHANGE_PASSWORD_FORM_NEW_PASSWORD = "Nueva contraseña";
  public CHANGE_PASSWORD_FORM_CONFIRM_PASSWORD = "confirmar Contraseña";
  public CHANGE_PASSWORD_SUBMIT_BUTTON = "Cambiar la contraseña";
  public CHANGE_PASSWORD_NEW_PASSWORD_REQUIRED = "Se requiere nueva contraseña";
  public CHANGE_PASSWORD_CONFIRM_PASSWORD_REQUIRED = "Confirmar Se requiere contraseña";
  public CHANGE_PASSWORD_CONFIRM_PASSWORD_INVALID = "Las contraseñas deben coincidir";
  public SOMETHING_WENT_WRONG = "Algo salió mal";
  public NO_DATA_AVAILABLE = "Datos no disponibles";
  public POP_UPS = {
    BACK_TO_DASHBOARD_POPUP: {
      title: "Cancelar",
      content: "¿Quieres cancelar tu cotización?",
      btnText: "Confirmar",
    },
    LOGOUT_POPUP: {
      title: "Cerrar sesión",
      content: "¿Le gustaría cerrar sesión en su cuenta?",
      btnText: "Confirmar",
    },
    SEND_QUOTES_POPUP: {
      title: "Enviar Cotización",
      content: "¿Ha revisado los planes para todos los productos seleccionados y ha confirmado sus elecciones?",
      btnText: "Enviar",
    },
    SEND_PROPOSAL_POPUP: {
      title: "Enviar propuesta",
      content: "¿Ha revisado los planes para todos los productos seleccionados y ha confirmado sus elecciones?",
      btnText: "Enviar",
    },
  };
  public PASSWORD_REQ = {
    title: "Requisitos de contraseña:",
    digit: "Al menos un dígito",
    lowerCase: "Al menos una letra minúscula",
    upperCase: "Al menos una letra mayuscula",
    specialChar: "Al menos un carácter especial",
    minLength: "Al menos contiene 10 caracteres.",
  };
  public QUOTES_PAYMENT_FREQUENCY = {
    monthly: "Por mes",
    yearly: "Por año",
  };
  public QUOTES_NOT_GENERATED = {
    title: `No pudimos encontrar los planes adecuados según su solicitud.`,
    subTitle: "Intente crear uno nuevo.",
    reason: "Razón",
  };
  public SUCCESS_PAGE_CONTENT = {
    line1: "Recibirá un email de confirmación pronto.",
    line2:
      "Si no recibe un correo electrónico dentro de las próximas dos horas o tiene alguna pregunta, comuníquese con nosotros en",
    email: "quotes@brokery.io ",
    line3: "¡Gracias!",
    msigLine2: "Para cualquier duda o consulta, por favor contacte a MSIG al ",
  };
  public ERROR_PAGE_CONTENT = {
    line1: "Error al enviar cotizaciones",
  };
  public CHATBOX = {
    heading: "¡Estamos aquí para ayudar!",
    hello: "¡Hola!",
    helpText: "Estamos aquí para ayudarte.",
    assistText: "¿Podría proporcionarnos su dirección de correo electrónico y una breve descripción de su pregunta?",
    email: "Correo electrónico",
    message: "Mensaje",
    submitQueryButtonText: "Enviar consulta",
    submitAnotherQueryButtonText: "Enviar otra consulta",
    thanksText: "¡Gracias por enviar tu consulta!",
    thanksDescText: `Hemos recibido su información y nos comunicaremos con usted.
    lo antes posible. Si tienes más preguntas, siéntete
    libre de enviar otra consulta.`,
    greatDayText: "¡Qué tengas un lindo día!",
    requiredText: "Requerido",
  };
  public FILTERS = {
    fromDate: "Partir de la fecha",
    toDate: "Hasta la fecha",
    filter: "Filtrar",
    insuranceProducts: "Productos de seguros",
    search: "Buscar",
    allSelected: "Todos los seleccionados",
    selectAll: "Seleccionar todo",
    clearAll: "Limpiar todo",
    hide: "Esconder",
    apply: "Aplicar",
    selected: "Seleccionado",
    noOptions: "Sin opciones",
  };
  public TABLES = {
    action: "Acción",
    view: "Vista",
    rowsPerPage: "Filas por página",
    of: "de",
    moreThan: "más que",
  };

  /**
   * Auth screens
   */
  public EMAIL = "Correo electrónico";
  public PASSWORD = "Contraseña";
  public FORGOT_PASSWORD_LINK = "¿Has olvidado tu contraseña?";
  public FORGOT_PASSWORD_DESCRIPTION = "Ingrese su correo electrónico registrado para registrar una nueva contraseña";
  public VERIFY_EMAIL = "Verificar correo electrónico";
  public VERIFY_EMAIL_INFO =
    "¡Genial! Hemos enviado un correo electrónico de verificación a tu dirección de correo electrónico registrada";
  public VERIFY_EMAIL_SUB_TEXT =
    "Por favor, revisa tu bandeja de entrada (o carpeta de spam) y haz clic en el enlace para confirmar tu cuenta. Una vez verificada, puedes iniciar sesión y empezar a usar la aplicación";
  public AVOID_LOCKED_OUT = "Evite quedar bloqueado.";
  public RESEND_EMAIL = "Reenviar correo electrónico";
  public SENT_PASS_RECOVERY_INSTRUCTIONS =
    "Hemos enviado instrucciones de recuperación de contraseña a su correo electrónico.";
  public VERIFY_USER_TO_PROCEED = "Por favor, verifica el usuario para continuar";
  public RESET_PASSWORD = "Restablecer la contraseña";
  public CHOOSE_NEW_PASSWORD = "Por favor elige una nueva contraseña";
  public CONFIRM_PASSWORD = "confirmar Contraseña";
  public RESET_PASSWORD_SUCCESSFUL = "Restablecimiento de contraseña exitoso";
  public RESET_PASSWORD_SUCCESSFUL_MESSAGE =
    "Ahora puede utilizar su nueva contraseña para iniciar sesión en su cuenta";
  public LOGIN = "Acceso";

  /**
   * Layout Translations
   */
  public MY_POLICIES = "Mis políticas";
  public ACCOUNT = "Cuenta";
  public CHOOSE_LANGUAGE = "Elige lengua";
  public FOOTER_TEXT = "© 2023 Stere, Inc. | Reservados todos los derechos";
  public TERMS = "Términos";
  public PRIVACY = "Privacidad";
  public LICENSE = "Licencias";
  public WELCOME = "Bienvenido {Nombre}";
  public WELCOME_WITHOUT_NAME = "Bienvenido";

  /**
   * Policy Listings
   */
  public POLICY_TERMS = "Término de la póliza";
  public INSURER = "Asegurador";
  public BROKER_ON_RECORD = "Corredor registrado";
  public ACTIVE = "Activo";
  public INACTIVE = "Inactivo";

  /**
   * Policy Details
   */
  public COVERAGE_DETAILS = "Detalles de cobertura";
  public COVERAGE = "Cobertura";
  public CANCEL_POLICY = "Política de cancelación";
  public RENEW_POLICY = "Renovar póliza";
  public POLICY = "Política";
  public DOCUMENTS = "Documentos";
  public AGENT_INFO = "Información del agente";
  public CONTACT_AGENT = "¿Preguntas o inquietudes? Comuníquese con el agente para obtener ayuda.";

  /**
   * Account page
   */
  public CONTACT_INFO = "Información del contacto";
  public NAME = "Nombre";
  public PHONE_NUMBER = "Número de teléfono";

  // Payment frequency
  public MONTHLY = "Mensual";
  public QUARTERLY = "Trimestral";
  public HALF_YEARLY = "Media anual";
  public BI_ANNUAL = "Semestral";
  public YEARLY = "Anual";

  public CONNECT_TO_SERVICE_USER = "Conectar-se ao usuário do serviço";
  public SUM_ASSURED = "Suma asegurada";

  public SELECT = "Seleccionar";

  public BEST = "La mejor";
  public SELLER = "Vendedor";

  public OR = "o";
  public NOTIFICATION_PAGE = {
    title: "Todas las notificaciones",
    accept: "Aceptar",
    decline: "Rechazar",
  };
  public FIRST_NAME = "Nombre de pila";
  public LAST_NAME = "Apellido";

  public FIRST_NAME_REQUIRED = "Se requiere el primer nombre";
  public LAST_NAME_REQUIRED = "Se requiere apellido";

  public PASSWORD_VALIDATION = {
    length: "La contraseña debe tener al menos 8 caracteres",
    numericChar: "La contraseña debe contener al menos un número",
    upperCaseLetter: "La contraseña debe contener al menos una letra mayúscula",
    lowerCaseLetter: "La contraseña debe contener al menos una letra minúscula",
    specialCharacter: "La contraseña debe contener al menos un carácter especial",
  };
  // Login page errors
  public LOGIN_REQUIRED_FIELD_MISSING = "Datos inválidos o campos obligatorios faltantes";
  public LOGIN_TOKEN_NOT_FOUND = "Token FFID inválido o usuario FF no encontrado para el token FFID proporcionado";
  public EXTERNAL_API_ISSUE = "Hay un problema con la API externa, por favor inténtelo de nuevo más tarde";
  public REDIRECT_TO_SOURCE = "Por favor, espere, lo estamos llevando a su destino...";

  // verification page
  public DONE_VERIFICATION = "Verificación completada, por favor inicie sesión para continuar";
  public VERIFYING_USER = "Verificando usuario...";
  public USER_ALREADY_REGISTERED = "El usuario ya está registrado, por favor inicie sesión";
  public DONE_VERIFICATION_ALERT = "Verificación de usuario completa, por favor inicie sesión para continuar.";

  // Agency details page
  public AGENCY_NAME = "Nombre de la Agencia";
  public TAX_ID = "ID Fiscal (CNPJ)";
  public AGENCY_ADDRESS = "Dirección de la Agencia";
  public LICENSE_NUMBER = "Número de Licencia (OPCIONAL)";
  public AGENCY_DETAILS = "Detalles de la Agencia";

  // verify email confirmation
  public PLEASE_CHECK_YOUR_EMAIL = "Por favor, revise su correo electrónico";
  public UNABLE_TO_RESEND_EMAIL = "No se pudo reenviar el correo electrónico";
  public VERIFY_EMAIL_GET_STARTED = "¡Verifique su correo electrónico para comenzar!";
  public SENT_VERIFICATION_EMAIL = "Hemos enviado un correo electrónico de verificación a";
  public ENSURE_ACCOUNT_SECURITY_MESSAGE =
    "Para garantizar la seguridad de su cuenta y desbloquear todas las funciones de la aplicación, haga clic en el enlace del correo electrónico para confirmar el registro";
  public CHECK_SPAM_FOLDER = "¿No lo encuentra? Revise su carpeta de spam";
  public YOUR_EMAIL = "su correo electrónico";
  public USER_NOT_EXIST = "Este usuario no existe. Por favor, regístrese primero.";
  public INVITATION_SENT = "Invitación enviada";

  public NO_SUFFICIENT_PERMISSION = "No tiene permisos suficientes";
  public INVITE_ALREADY_ACCEPTED = "La invitación ya ha sido aceptada";
  public INVALID_AUTH_KEY = "Por favor, inicie sesión primero";
  public INVITE_ACCEPTED = "Invitación aceptada";
  public REGISTER_OR_LOGIN_BEFORE_ACCEPTING_INVITE =
    "Por favor, regístrese si es un usuario nuevo o inicie sesión antes de aceptar la invitación";
  public ACCEPTING_INVITE = "Aceptando invitación";
  public NO_RECORD_FOUND = "No se encontraron registros";

  public CNPJ_IS_REQUIRED = "CNPJ es obligatorio";
  public INVALID_CNPJ_FORMAT = "Formato de CNPJ inválido";
  public INVALID_CNPJ = "CNPJ inválido";
  public AGENCY_NAME_IS_REQUIRED = "Nombre de la Agencia es obligatorio";
  public AGENCY_ADDRESS_IS_REQUIRED = "Dirección de la Agencia es obligatoria";
  public INVALID_MEMBER_ID = "ID de miembro inválido";
  public MEMBER_DELETED_SUCCESSFULLY = "Miembro eliminado con éxito";
  public NO_NEW_NOTIFICATION_AVAILABLE = "No hay nuevas notificaciones";

  public UPDATE = "Actualizar";
  public ACCOUNT_UPDATED = "Cuenta actualizada";
  public PROFILE_UPDATE = "Perfil actualizado";
  public VERIFY_EMAIL_LABEL = "Verificar correo electrónico";
  public AVOID_GETTING_LOCKED_OUT = "Evite quedar bloqueado.";
  public PASSWORD_RECOVERY_INSTRUCTIONS_SENT =
    "Hemos enviado instrucciones de recuperación de contraseña a su correo electrónico.";
  public PASSWORD_RECOVERY_INSTRUCTIONS_SENT_TOAST =
    "Instrucciones de recuperación de contraseña enviadas a su correo electrónico";
  public DONE_RESETTING_PASSWORD = "Contraseña restablecida, por favor inicie sesión";
  public INVALID_TOKEN = "Token inválido";
  public CONFIRM_PASSWORD_REQUIRED = "Confirmar contraseña es obligatorio";
  public PASSWORDS_MUST_MATCH = "Las contraseñas deben coincidir";
  public SUBMIT = "Enviar";
  public NO_APPOINTMENT_AVAILABLE = "No hay citas disponibles";

  public FILTER_APPOINTMENTS = "Filtrar citas...";
  public STATUS = "Estado";
  public VALIDITY = "Validez";
  public UPCOMING = "Próximas";
  public OUTDATED = "Vencidas";
  public PENDING = "Pendiente";
  public APPROVED = "Aprobada";
  public REJECTED = "Rechazada";

  public GO_BACK = "Volver";
  public REQUEST_APPOINTMENT = "Solicitar cita";

  public PRODUCT = "Producto";
  public CREATED_AT = "Creado El";

  public INSURER_LABEL = "Aseguradora";
  public CONFIRM_SUBMISSION_TITLE = "Confirmar envío";
  public CONFIRM_SUBMISSION_MESSAGE = "¿Está seguro de que desea enviar su solicitud de cita?";
  public INSURER_REQUIRED = "El asegurador es obligatorio";
  public PRODUCT_REQUIRED = "El producto es obligatorio";

  public NO_RESULTS_FOUND = "No se encontraron resultados";

  public ERROR_FETCHING_INSURER = "Ocurrió un error al obtener los detalles de la aseguradora";

  public ERROR_LOADING_INSURER_PRODUCTS_DATA =
    "Ocurrió un error al cargar los datos de los productos de la aseguradora";
  public ERROR_LOADING_INSURER_DATA = "Ocurrió un error al cargar los datos de la aseguradora";
  public APPOINTMENT_ALREADY_EXISTS = "La cita ya existe";

  public USER_ROLE_IS_REQUIRED = "El rol de usuario es un campo obligatorio";
  public I_AM_ADMIN = "Soy administrador";
  public I_AM_AGENT = "Soy agente";
  public CPF = "CPF";
  public CPF_IS_REQUIRED = "El CPF es obligatorio";

  public TO_ACCESS_ALL_FEATURES = "Para acceder a todas las funciones, necesitas ser parte de una cuenta de corredor";
  public BROKER_ACCOUNT_ALREADY_EXISTS = "La cuenta de corredor ya existe";
  public BROKER_ACCOUNT_WITH_SAME_CNPJ_EXISTS =
    "Una cuenta de corredor con el mismo CNPJ ya existe. Puedes solicitar acceso al administrador de la cuenta existente o ingresar un CNPJ válido.";
  public ACCOUNT_DETAILS = "Detalles de la cuenta";
  public ADMIN = "Administrador";
  public AGENCY = "Agencia";
  public BACK_TO_LOGIN = "Volver al inicio de sesión";
  public REQUEST_TO_JOIN_ACCOUNT = "Solicitar unirse a la cuenta";
  public CREATE_BROKER_ACCOUNT = "Crear una cuenta de corredor";
  public BECOME_ADMIN_AND_BUILD_TEAM =
    "Conviértete en administrador y construye tu equipo creando tu propia cuenta de corredor.";
  public NAVIGATE_TO_PROFILE =
    "Para hacerlo, navega a la sección de tu perfil y agrega los detalles de tu agencia de corredores.";
  public MY_PROFILE = "Mi perfil";
  public REQUEST_ACCEPT_INVITE = "Solicitar / Aceptar una invitación";
  public CHECK_NOTIFICATION_CENTER =
    "Por favor, revisa tu centro de notificaciones para cualquier invitación pendiente.";
  public REQUEST_JOIN_BROKER_ACCOUNT =
    "Solicita unirte a una cuenta de corredor ingresando la dirección de correo electrónico del administrador.";
  public EMAIL_ADDRESS = "Dirección de correo electrónico";
  public SEND_REQUEST = "Enviar solicitud";
  public NOTIFICATION_CENTER = "Centro de notificaciones";
  public GET_FULL_ACCESS = "Obtener acceso completo";
  public ADD_AGENCY_DETAILS = "Agregar detalles de la agencia";
  public MEMBERSHIP_REQUEST_SENT = "Solicitud de membresía enviada";
  public ADMIN_NOT_FOUND = "Administrador no encontrado";
  public MEMBERSHIP_ALREADY_REQUESTED = "Membresía ya solicitada";
  public INVITES = "Invitaciones";
  public MEMBERSHIP_REQUEST = "Solicitud de Membresía";
  public IS_REQUESTING_TO_JOIN = "está solicitando unirse";
  public USER_IS_ALREADY_MEMBER = "El usuario ya es miembro";
  public MEMBERSHIP_REQUEST_APPROVED = "Solicitud de membresía aprobada";
  public MEMBERSHIP_REQUEST_REJECTED = "Solicitud de membresía rechazada";
  public INVALID_MEMBERSHIP_ID = "ID de membresía inválido";
  public PROCEEDING_WILL_SEND_REQUEST =
    "Al proceder, se enviará una solicitud al administrador para unirse, y tu perfil de usuario será marcado como un agente de esa cuenta.";
  public REQUEST_TO_JOIN = "Solicitar Unirse";
  public CANCELLED = "Cancelado";
  public EXPIRED = "Expirado";

  public NO_ASSIGNED_APPOINTMENT = "No tienes ninguna cita asignada. Por favor, ve a solicitar una cita para continuar";
  public CLOSE = "Cerrar";
  // Proposal Flow
  public ACCEPT_PROPOSAL = "Aceptar propuesta";
  public PROPOSAL_ACCEPTED = "Propuesta aceptada";
  public READ_TERMS = "Lee y marca los términos a continuación para proceder a la contratación de tu seguro";
}

export default TranslationEs;
