import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import LoginBg from "assets/images/login-img.png";
import StereLogo from "assets/logos/StereLogo";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { requestResetPasswordApi } from "modules/Auth/api";
import { IRequestResetPassword } from "modules/Auth/types";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import * as Yup from "yup";

const forgotPasswordInitialValues = {
  email: "",
};

const EmailSentComponent = () => {
  const customStyles = useSelector(customStylesSelector);
  const { t } = useT();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: "20%",
      }}
    >
      <Box fontSize={"50px"}>
        <CheckCircleIcon color="success" fontSize="inherit" />
      </Box>
      <Typography
        sx={{
          fontWeight: 600,
          textAlign: "center",
          fontSize: "18px",
          color: customStyles.muiTheme.palette.primary.main,
        }}
      >
        {t("VERIFY_EMAIL_LABEL")}
      </Typography>
      <Typography
        sx={{
          mb: "25px",
          fontWeight: 500,
          textAlign: "center",
          fontSize: "12px",
          color: customStyles.muiTheme.palette.text.tertiary,
        }}
      >
        {t("AVOID_GETTING_LOCKED_OUT")}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          textAlign: "center",
          fontSize: "14px",
          color: customStyles.muiTheme.palette.text.secondary,
        }}
      >
        {t("PASSWORD_RECOVERY_INSTRUCTIONS_SENT")}
      </Typography>

      <AnimatedButton
        variant="contained"
        sx={{ marginTop: "20px" }}
        onClick={() => navigate("/login")}
      >
        {t("LOGIN")}
      </AnimatedButton>

    </Box>
  );
};

function RequestResetPassword() {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const { setToast } = useContext(ToastCtx);
  const navigate = useNavigate();
  const requestResetPasswordMutation = useMutation<
    unknown,
    any,
    IRequestResetPassword
  >({
    mutationFn: requestResetPasswordApi,
    onSuccess: () => {
      setToast({
        severity: "success",
        text: t("PASSWORD_RECOVERY_INSTRUCTIONS_SENT_TOAST"),
      });
    },
    onError: () => {
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const forgotPasswordValidationSchema = Yup.object().shape({
    email: Yup.string().email(t("INVALID_EMAIL")).required(t("EMAIL_REQUIRED")),
  });

  const handleForgotPasswordSubmit = (
    values: typeof forgotPasswordInitialValues
  ) => {
    requestResetPasswordMutation.mutate({ email: values.email });
  };

  const ForgotPasswordComponent = () => (
    <Box sx={{ mt: "80px" }}>
      <Typography
        sx={{
          mb: "10px",
          fontWeight: 600,
          textAlign: "center",
          fontSize: "18px",
          color: customStyles.muiTheme.palette.primary.main,
        }}
      >
        {t("FORGOT_PASSWORD")}
      </Typography>
      <Typography
        sx={{
          mb: "30px",
          fontWeight: 400,
          textAlign: "center",
          fontSize: "14px",
          color: customStyles.muiTheme.palette.text.secondary,
        }}
      >
        {t("FORGOT_PASSWORD_MESSAGE")}
      </Typography>
      <Formik
        initialValues={forgotPasswordInitialValues}
        validationSchema={forgotPasswordValidationSchema}
        validateOnMount={true}
        onSubmit={handleForgotPasswordSubmit}
      >
        {({ isValid }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Field
                as={TextField}
                type="email"
                label={t("EMAIL_LABEL")}
                name="email"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                }}
                InputProps={{
                  style: {
                    borderRadius: "12px",
                  },
                }}
              />
              <ErrorMessage
                className="error-message"
                name="email"
                component="div"
              />
            </Box>
            <AnimatedButton
              type="submit"
              sx={{ textTransform: "none", mt: "30px" }}
              disabled={!isValid}
              variant="contained"
              isLoading={requestResetPasswordMutation.isPending}
            >
              {t("RECOVER_PASSWORD")}
            </AnimatedButton>
            <AnimatedButton
              onClick={() => navigate('/login')}
              sx={{ textTransform: "none", mt: "20px" }}
              variant="outlined"
            >
              {t("BACK_TO_LOGIN")}
            </AnimatedButton>
          </Form>
        )}
      </Formik>
    </Box>
  );

  return (
    <Box {...animateRouteProps}>
      <Grid className="loginForm" container>
        <Grid
          item
          md={6}
          sm={7}
          xs={12}
          sx={{
            height: "100vh",
            p: "20px",
            pt: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <StereLogo />
          </Box>
          {requestResetPasswordMutation.isSuccess ? (
            <EmailSentComponent />
          ) : (
            <ForgotPasswordComponent />
          )}
        </Grid>
        <Grid
          item
          md={6}
          sm={5}
          sx={{
            height: "100vh",
            position: "relative",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={LoginBg}
              alt="Login Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <Box
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                backgroundColor: customStyles.muiTheme.palette.primary.main,
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0.4,
              }}
            ></Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RequestResetPassword;
