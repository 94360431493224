import { Divider, Grid, TextField, Paper, Typography, Box } from "@mui/material";
import AnimatedButton from "components/custom/AnimatedButton";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useT } from "translation";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { sendMemberShipRequestAPI } from "modules/Accounts/api";
import { useContext } from "react";
import { ToastCtx } from "components/Toast/ToastProvider";

const sendMemberShipRequestInitialValues = {
  email: '',
}
function RequestAcceptInvite() {
  const { t } = useT();
  const pendingInvites = useSelector((state: RootState) => state.auth?.accounts?.invitedAccounts);
  const { setToast } = useContext(ToastCtx);
  const navigate = useNavigate();
  const sendMemberShipRequestMutation = useMutation<unknown, any, string>({
    mutationFn: sendMemberShipRequestAPI,
    onSuccess: () => {
      setToast({
        severity: "success",
        text: t("MEMBERSHIP_REQUEST_SENT"),
      });
    },
    onError: ({ error: { code } }: { error: { code: string } }) => {
      if (code === "admin_not_found") {
        setToast({
          severity: "error",
          text: t("ADMIN_NOT_FOUND"),
        });
        return;
      }
      if (code === "membership_already_requested") {
        setToast({
          severity: "error",
          text: t("MEMBERSHIP_ALREADY_REQUESTED"),
        });
        return;
      }
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const sendMemberShipRequestValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("INVALID_EMAIL"))
      .required(t("EMAIL_REQUIRED")),
  });
  const handleSendMemberShipRequest = (values: typeof sendMemberShipRequestInitialValues) => {
    sendMemberShipRequestMutation.mutate(values.email);
  };
  return (
    <Paper sx={{ p: 2, height: "100%" }}>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 3 }}>{t("REQUEST_ACCEPT_INVITE")}</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>{t("CHECK_NOTIFICATION_CENTER")}</Typography>

      <AnimatedButton
        sx={{ mb: 2 }}
        endIcon={<ArrowForwardIcon />}
        disabled={pendingInvites.length === 0}
        onClick={() => navigate('/home/notifications')}
      >
        {t("NOTIFICATION_CENTER")}
      </AnimatedButton>

      <Divider sx={{ mb: 2 }}>{t("OR")}</Divider>
      <Typography variant="body2" sx={{ mb: 2 }}>{t("REQUEST_JOIN_BROKER_ACCOUNT")}</Typography>

      <Formik
        initialValues={sendMemberShipRequestInitialValues}
        validationSchema={sendMemberShipRequestValidationSchema}
        validateOnMount={true}
        onSubmit={handleSendMemberShipRequest}
      >
        {({ isValid }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >

            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Box>
                  <Field
                    as={TextField}
                    type="email"
                    label={t("EMAIL_LABEL")}
                    name="email"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "12px",
                        height: "50px",
                      },
                      inputProps: {
                        style: {
                          height: "100%",
                          padding: "0 12px",
                        },
                      },
                    }}
                  />

                  <ErrorMessage
                    className="error-message"
                    name="email"
                    component="div"
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <AnimatedButton
                  variant="contained"
                  type="submit"
                  sx={{ height: "50px" }}
                  isLoading={sendMemberShipRequestMutation.isPending}
                >
                  {t("SEND_REQUEST")}
                </AnimatedButton>
              </Grid>
            </Grid>
          </Form>)}
      </Formik>
    </Paper>
  )
}


export default RequestAcceptInvite