import { useQuery } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import { debounce } from "lodash";
import { getProducts } from "modules/Appointments/api";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useT } from "translation";

const useProductFilters = () => {
  const { setToast } = useContext(ToastCtx)
  const { t } = useT();
  const [searchInput, setSearchInput] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchInput = useCallback(
    debounce((value: string) => setSearchInput(value), 300),
    [setSearchInput]
  );

  const {
    data: productsData,
    isError: isProductsLoadingError,
    isLoading
  } = useQuery({
    queryFn: () => getProducts.queryFn(searchInput),
    queryKey: getProducts.queryKey(searchInput),
  });

  useEffect(() => {
    if (isProductsLoadingError) {
      setToast({
        text: t('ERROR_FETCHING_PRODUCTS'),
        severity: 'error',
      });
    }
  }, [isProductsLoadingError, setToast, t]);

  const allProducts: SelectOption[] = useMemo(
    () =>
      (productsData?.insurance_products || []).map((p) => ({
        label: p,
        value: p,
      })),
    [productsData?.insurance_products]
  );
  return { allProducts, handleSearchInput: debouncedSetSearchInput, isLoading }

}

export default useProductFilters;