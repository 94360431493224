import api from "utils/api.utils";

export const approveMembershipRequest = async (memberId: string, accountId: string) => {
  try {
    const response = await api.put(`members/${memberId}/approve`, {}, {
      'X-ACCOUNT-ID': accountId
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const rejectMembershipRequest = async (memberId: string, accountId) => {
  try {
    const response = await api.put(`members/${memberId}/reject`, {}, {
      'X-ACCOUNT-ID': accountId
    });
    return response;
  } catch (err) {
    throw err;
  }
};
